import React, {useEffect, useState} from 'react';
import {Button, Select, MenuItem, TextField, FormControl,
    InputLabel,
} from '@mui/material';
import backendApi from '../../api/BackendApi.js';
import '../../css/Tutors.css';
import '../../css/placement.css';
import { useLocation } from "react-router-dom";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment' // 292.3K (gzipped: 71.6K)
import Message from '../Message.js';

const Invoice = () => {
    const location = useLocation();

    const [optContracts, setOptContracts] = useState([]);
    const [contract, setContract] = useState('');

    const [invoiceAmount, setInvoiceAmount] = useState('');
    const [invoiceDate, setInvoiceDate] = useState(new Date());
    const [notes, setNotes] = useState('');
    const [invoiceHistory, setInvoiceHistory] = useState([]);

    const [contractId, setContractId] = useState('');
    const [contractStart, setContractStart] = useState('');
    const [contractEnd, setContractEnd] = useState('');
    const [purchaseOrder, setPurchaseOrder] = useState('');
    const [school, setSchool] = useState('');
    const [contractAmount, setContractAmount] = useState('');
    const [realContractAmount, setRealContractAmount] = useState(0);
    const [totalInvoice, setTotalInvoice] = useState(0);
    const [leftOver, setLeftOver] = useState(0);

    const [message, setMessage] = useState("");
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    
    useEffect(() => {
        if (location.state) {
            if(Object.keys(location.state).length > 0) {
                setContract(location.state.id);
                setContractStart(location.state.start_date);
                setContractEnd(location.state.end_date);
                setSchool(location.state.name);
            }
        }

        let tempOptions = ['']
        backendApi.get("/contract/all")
            .then(data => {
                data.map((item, idx) => (
                    tempOptions.push({label: item.contract_num, value: item.id})
                ));
                setOptContracts(tempOptions);
            })
            .catch((error) => {
                console.log(error.code)
                if (error.code === 'permission-denied') {
                }
            });
    }, [location.state]);

    const handleSelectContract = (value) => {
        setContract(value);
        let obj = optContracts.find(o => o.value === value);
        const data = {number: obj.label};
        backendApi.get("/contract/contract/", data)
            .then(result1 => {
                setContractId(result1.contract.id);
                setSchool(result1.contract.name);
                setContractStart(result1.contract.start_date);
                setContractEnd(result1.contract.end_date);
                setPurchaseOrder(result1.contract.purchase_order);
                // setContractAmount(result1.contract.amount);
                setContractAmount("$ "+ result1.contract.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
                setRealContractAmount(result1.contract.amount);

                const data2 = {contract: result1.contract.id};
                backendApi.get("/invoice", data2)
                .then(result => {
                    setInvoiceHistory(result.invoices);

                    // let sum = result.reduce((accumulator, object) => {
                    //     return accumulator + parseFloat(object.amount);
                    //   }, 0);

                    setTotalInvoice("$ " + result.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
                    let temp = result1.contract.amount - result.total;
                    setLeftOver("$ " + temp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
                })
                .catch((error) => {
                    console.log(error.code)
                    if (error.code === 'permission-denied') {
                    }
                });

            })
            .catch((error) => {
                console.log(error.code)
                if (error.code === 'permission-denied') {
                }
            });
    }

    const createInvoice = () => {
        // if((school !== '') && (start !== '') && (end !== '')) {
            const data = {
                contract_id: contractId,
                date: moment(invoiceDate).format("YYYY-MM-DD"),
                // purchase_order: purchaseOrder,
                notes: notes,
                amount: parseFloat(invoiceAmount),
            }
            backendApi.post("/invoice/", data)
                .then(result => {
                    if (result === false) {
                        setMessage('Create invoice failed.');
                    } else {
                        setMessage('Create invoice success.')
                        setInvoiceHistory(result.invoices);    
                        setTotalInvoice("$ " + result.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
                        let temp = realContractAmount - result.total;
                        setLeftOver("$ " + temp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
                    }
                    setOpen(true);
                })
                .catch(error => {
                    console.log(error.code);
                });
        // } else {
        //     setMsg("Missing information");
        //     setOpen(true);
        // }
    }

    return (
        <div className="body-container">

            <Message
                message={message}
                open={open}
                btn1={'OK'}
                handleClose={handleClose}
                handleBtn1={handleClose}
            />

            <h4> Invoices </h4>
            <div className='placement-whole'>
                <div className="invoice-thirds">
                    <div className='invoice-title'> Select Contract </div>

                    <FormControl
                        variant="filled"
                        style={{ m: 1, width: '70%' }}
                    >
                        <InputLabel id="test-select-label">Contract</InputLabel>
                        <Select
                            sx={{ color: 'black', width: '100%' }}
                            labelId="test-select-label"
                            id="contract"
                            value={contract}
                            name={'contract'}
                            label="Contract"
                            onChange={e=>handleSelectContract(e.target.value)}
                        >
                            {optContracts.map((item, idx) => {
                                return(
                                    <MenuItem key={"optContracts"+idx} value={item.value}>{item.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>

                    <TextField
                        sx={{ m: 1, width: '70%' }}
                        required
                        variant="filled"
                        id="school"
                        label="School"
                        value={school}
                    />

                    <TextField
                        sx={{ m: 1, width: '70%' }}
                        required
                        variant="filled"
                        id="contract-start"
                        label="Contract Start"
                        value={contractStart}
                    />

                    <TextField
                        sx={{ m: 1, width: '70%' }}
                        required
                        variant="filled"
                        id="contract-end"
                        label="Contract End"
                        value={contractEnd}
                    />

                    <TextField
                        sx={{ m: 1, width: '70%' }}
                        required
                        variant="filled"
                        id="purchase-order"
                        label="Purchase Order"
                        value={purchaseOrder}
                    />

                    <TextField
                        sx={{ m: 1, width: '70%' }}
                        required
                        variant="filled"
                        id="contract-amount"
                        label="Contract Amount"
                        value={contractAmount}
                    />
                </div>
                
                <div className="invoice-thirds">
                    <div className='invoice-title'> Invoice History </div>
                    <div className='invoice-detail'> {totalInvoice} out of {contractAmount} spent </div>
                    <div className='invoice-detail'> $ Left = {leftOver} </div>

                    <div className='invoice-history-table'>
                        <div className='invoice-history-row'>
                            <div className='invoice-history-column'> Date </div>
                            <div className='invoice-history-column'> Amount </div>
                        </div>
                        {invoiceHistory.map((entry, idx) => {
                            return(
                                <div key={'hist'+idx} className='invoice-history-row'>
                                    <div className='invoice-history-column'> {entry.date} </div>
                                    <div className='invoice-history-column'> $ {entry.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </div>
                                </div>
                            )
                        })}

                    </div>
                </div>


                <div className='invoice-thirds'>
                    <div className='invoice-title'> Create Invoice </div>
                    <div className='invoice-date'>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Invoice Date"
                                value={invoiceDate}
                                onChange={e => setInvoiceDate(e)}
                                renderInput={params => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </div>

                    <TextField
                        sx={{ m: 1, width: '60%' }}
                        required
                        id="invoice-amount"
                        label="Invoice Amount"
                        variant="filled"
                        value={invoiceAmount}
                        onChange={e => setInvoiceAmount(e.target.value)}
                    />

                    <TextField
                        sx={{ m: 1, width: '60%' }}
                        required
                        id="invoice-notes"
                        label="Invoice Notes"
                        variant="filled"
                        value={notes}
                        onChange={e => setNotes(e.target.value)}
                    />

                    <div>
                        <Button 
                            sx={{ m: 1, width: '60%', }}
                            variant="outlined" 
                            onClick={createInvoice}
                        >
                            Create Invoice
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Invoice;