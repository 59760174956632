import React from 'react';
import '../css/Tutors.css';

const Help = () => {
    return (
        <div className="help-main">
            <div className="help">Directions on how to create contracts and view "Reve styled" reports per contract</div>
            <div className="help">1. Create a school</div>
            <div className="help-tab">    a. Go to Create School page</div>
            <div className="help-tab">    b. Fill out fields</div>
            <div className="help-tab">    c. Submit</div>
            <div className="help">2. Create a contract</div>
            <div className="help-tab">    a. Go to Create Contract page</div>
            <div className="help-tab">    b. Select school from dropdowm menu (mandatory)</div>
            <div className="help-tab">    c. Enter start date of contract (mandatory)</div>
            <div className="help-tab">    d. Enter end date of contract (mandatory)</div>
            <div className="help-tab">    e. Make note of contract name: 1st 4 letters of school-startDate-endDate</div>
            <div className="help">3. Create assignments</div>
            <div className="help-tab">    a. Go to Assignments page.</div>
            <div className="help-tab">    b. On left hand side, select contract created in step 2 from dropdown menu </div>
            <div className="help-tab2">        i. Once selected, contract details will populate directly below the dropdown.</div>
            <div className="help-tab">    c. Add tutor to contract </div>
            <div className="help-tab2">        i. Select a tutor using the dropdown on the right side</div>
            <div className="help-tab2">        ii. Press the 'plus' button next to the tutor name that was selected</div>
            <div className="help-tab2">        iii. Tutor should show up in list below the dropdown along with all tutors already selected to work on the contract</div>
            <div className="help-tab">    d. To remove tutor from the contract</div>
            <div className="help-tab2">        i. Press the 'minus' button next to the tutor name</div>
            <div className="help-tab2">        ii. Verify that tutor name has been removed form list on right side</div>
            <div className="help">4. Create "Reve styled" report</div>
            <div className="help-tab">    a. Go to View Schools page</div>
            <div className="help-tab">    b. Find desired school in the list</div>
            <div className="help-tab">    c. Next to the desired school, press the report icon to see report.</div>
            <div className="help-tab">    d. The report will show "Reve styled" report for selected contract.</div>
        </div>
    );
}

export default Help;