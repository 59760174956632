import React, {useState,} from 'react';
// import PropTypes from 'prop-types';
import { 
    Paper, Table, TableBody, TableCell,
    TableContainer, TableHead, TablePagination,
    TableRow, Button,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import backendApi from '../../api/BackendApi.js';
import moment from 'moment' // 292.3K (gzipped: 71.6K)
import '../../css/Tutors.css';
import { 
    faEdit,
    faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';

const TableContracts = ({
    data=null,
    columns=null,
    pathname=null,
    title="",
    handleContract=null,
}) => {
    let history = useHistory();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const styles = {
        tableCell: {
          padding: "5px 16px"
        },
        
        tableContainer: {
            maxHeight: 440,
            direction: "column",
            justifyContent: "center",
            alignItems: "center"
        },

        page: {
            margin: '2ch auto',
            width: '90%',
            overflow: 'hidden',
            justifyContent: "center",
            alignItems: "center",
            direction: "column"
        },

        button: {
            size: 'large',
            m: 1,
            width: '5ch',
            fontSize: '3ch',
        },
    };

    const handleSelect = (page, index) => {
        handleContract(data[(page*10) + index]);
    }

    const handleReport = (index) => {
        const data2 = {
            school: data[index].school,
            year: moment().format("YYYY"),
            file: "report_" + moment().format("YYYY-MMM") + ".csv"
        };
        backendApi.download('/report/hoursPerTutorBySchoolAndDate', data2);
    }

    const handleRoster = (page, index) => {
        history.push({
            pathname: 'view-placement',
            state: data[(page*10) + index],
        });
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <div className="table-container">
            <Paper sx={styles.page}>
                <TableContainer sx={styles.tableContainer}>
                    <Table
                        stickyHeader
                        aria-label="sticky table"
                    >
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={'ts_'+column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {data
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, idx) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={'ts_tr_'+idx}>
                                    {columns.map((column) => {
                                        const value = row[column.id];
                                        const obj = (JSON.parse(sessionStorage.getItem('user')));
                                        return (
                                            <TableCell key={'ts_cb_'+column.id} align={column.align} sx={styles.tableCell}>
                                                {(obj.role === 'ROLE_ADMIN' && column.id === 'edit') ?
                                                    <Button
                                                        sx={styles.button}
                                                        // variant="outlined"
                                                        onClick={()=>handleSelect(page, idx)}
                                                    >
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </Button> 
                                                    : (obj.role === 'ROLE_ADMIN' && column.id === 'report') ?
                                                        <Button
                                                            sx={styles.button}
                                                            // variant="outlined"
                                                            onClick={()=>handleReport(idx)}
                                                        >
                                                            <FontAwesomeIcon icon={faFileAlt} />
                                                        </Button> 
                                                        : (obj.role === 'ROLE_ADMIN' && column.id === 'roster') ?
                                                            <Button
                                                                sx={styles.button}
                                                                // variant="outlined"
                                                                onClick={()=>handleRoster(page, idx)}
                                                            >
                                                                <FontAwesomeIcon icon={faUsers} />
                                                            </Button> 
                                                            : (column.format && typeof value === 'number') ?
                                                                column.format(value) : value
                                                }
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}

// ViewTimeSheets.propTypes = {
//     name: PropTypes.string
//   };

export default TableContracts