import React, {useState} from 'react';
import {Button, TextField, FormControl, InputLabel, Select, MenuItem,
    FormControlLabel, Checkbox, Modal, Box} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment' // 292.3K (gzipped: 71.6K)
import Message from '../Message.js';
import backendApi from '../../api/BackendApi.js';
import '../../css/Tutors.css';

const CreateTimesheetModal = ({
    open_modal=false,
    update=false,
    handleClose=null,
    timesheetData=null,
    setTimesheetData=null,
    createNew=null,
    modifyExisting=null,
    optTutors=null,
}) => {
    const [openStatusModal, setOpenStatusModal] = useState(false);
    const [statusMsg, setStatusMsg] = useState('');
    const handleCloseSub = () => setOpenStatusModal(false);
    const [optContracts, setOptContracts] = useState([{label: '', value: 0}]);
    
    const breakTimes = [
        {label: 'None', value: 0},
        {label: '15 min', value: 15},
        {label: '30 min', value: 30},
        {label: '45 min', value: 45},
        {label: '1 hr', value: 60},
        {label: '1 hr 15 min', value: 75},
        {label: '1 hr 30 min', value: 90},
        {label: '2 hr', value: 120},
    ];

    const desktop = useMediaQuery('(min-width:450px)');

    const style = {
        box: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '60%',
            height: '80%',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 1,
        },
    
        time: {
            width: '16%',
            marginTop: '1em',
            marginRight: '1em',
        },
    
        break: {
            width: '16%',
            marginTop: '1em',
            marginRight: '1em',
        },

        hrsWorked: {
            width: '16%',
            marginTop: '1em',
        },
    
        date: {
            width: '20%',
            marginTop: '1em',
            marginRight: '5em',
        },
    
        selectContainer: {
            width: desktop ? '40%' : '80%',
            marginRight: '1em',
        },
    
        select: {
            color: 'black',
            width: '100%',
        },
    
        notes: {
            width: desktop ?'60%' : '80%',
        },

        absent: {
            marginTop: '1em',
        }
    };
  
    const handleSubmit = () => {
        if (!timesheetData.absent && (parseFloat(timesheetData.hours_worked) <= 0)) {
            setStatusMsg('Times are not valid');
            setOpenStatusModal(true);
        } else if (parseFloat(timesheetData.hours_worked) > 10) {
            setStatusMsg("Hours worked > 10. Re-enter or get Admin approval.");
            setOpenStatusModal(true);
        } else {
            createNew();
        }
    }

    const handleTimeIn = (e) => {
        if(!timesheetData.absent) {
            let clone = { ...timesheetData };
            let hours_worked = clone.hours_worked;
            if(timesheetData.time_out !== '') {
                hours_worked = autoTimeElapsed(e, timesheetData.time_out, timesheetData.break_duration);
            }

            localStorage.setItem('time_in', e);
            clone.time_in = e;
            clone.hours_worked = hours_worked;
            setTimesheetData(clone);
        }
    }

    const handleTimeOut = (e) => {
        if(!timesheetData.absent) {
            let clone = { ...timesheetData };
            let hours_worked = clone.hours_worked;
            if(timesheetData.time_in !== '') {
                hours_worked = autoTimeElapsed(timesheetData.time_in, e, timesheetData.break_duration);
            }

            localStorage.setItem('time_out', e);
            clone.time_out = e;
            clone.hours_worked = hours_worked;
            setTimesheetData(clone);
        }
    }

    function autoTimeElapsed (tIn, tOut, tBreak) {
        tIn = moment(tIn);
        tOut = moment(tOut);
        let duration = moment.duration(tOut.diff(tIn));
        let hours = duration.asHours();
        let fractional = hours % 1;
        let mm =Math.round(fractional*4)/4;
        let revisedElapsed = parseInt(hours, 10) + mm;
        let hw = revisedElapsed - (tBreak/60.0);
        return isNaN(hw) ? 0 : hw > 0 ? hw : 0;
    }

    const handleSelectBreak = (value) => {
        if(!timesheetData.absent) {
            let clone = { ...timesheetData };
            localStorage.setItem('break', value);
            clone.break_duration = value;
            clone.hours_worked = autoTimeElapsed(timesheetData.time_in, timesheetData.time_out, value);
            setTimesheetData(clone);
        }
    }

    const handleAbsent = (e) => {
        let clone = { ...timesheetData };
        clone.absent = e.target.checked;

        if (e.target.checked) {
            clone.time_in = moment("12:00:00 AM", "h:mm:ss A");
            clone.time_out = moment("12:00:00 AM", "h:mm:ss A");
            clone.hours_worked = 0;
            clone.break_duration = 0;
        }
        setTimesheetData(clone);
    }

    const handleTimesheetData = (value, index) => {
        let clone = { ...timesheetData };
        clone[index] = value;
        setTimesheetData(clone);
    }

    const handleSelectContract = (value) => {
        let clone = { ...timesheetData };
        clone['assignment'] = value;
        setTimesheetData(clone);
    }

    const getCurrentAssignments = (emp_id, date) => 
    {        
        let params = {
            emp_id: emp_id,
            day: moment(date).format("YYYY-MM-DD"),
        };

        backendApi.get("/contract/emp_contracts/", params)
            .then(data => {
                let tempContracts = [];
                data.map((item) => (
                    tempContracts.push({label: item.school, value: item.assignment})
                ));
                setOptContracts(tempContracts);

                let clone = { ...timesheetData };
                clone['tutor'] = emp_id;
                clone['date'] = date;
                clone['assignment'] = tempContracts[0].value;
                setTimesheetData(clone);
            })
            .catch((error) => {
                console.log(error.code)
                if (error.code === 'permission-denied') {
                    // setMsg("Insufficient permission");
                }
            });
    }

    return (
        <Modal
            open={open_modal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style.box}>
                {update?
                    <h4> Modify Timesheet </h4>
                    :
                    <h4> Timesheet Entry </h4>
                }

                <Message
                    message={statusMsg}
                    open={openStatusModal}
                    btn1={'OK'}
                    handleClose={handleCloseSub}
                    handleBtn1={handleCloseSub}
                />

                <div className="ts-entry-row">
                    {update ?
                        <div> {timesheetData.name} </div>
                    :
                        <FormControl
                            variant="outlined"
                            margin={"dense"}
                            style={style.selectContainer}
                        >
                            <InputLabel id="test-select-label">Tutor</InputLabel>
                            <Select
                                sx={style.select}
                                labelId="test-select-label"
                                id="tutor"
                                value={timesheetData.tutor}
                                label="Tutor"
                                onChange={e=>getCurrentAssignments(e.target.value, timesheetData.date)}
                            >
                                {optTutors.map((item, idx) => {
                                    return(
                                        <MenuItem key={"optTutors"+idx} value={item.value}>{item.label}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>                                
                    }

                    <FormControl
                        variant="outlined"
                        margin={"dense"}
                        style={style.selectContainer}
                    >
                        <InputLabel id="test-select-label">School</InputLabel>
                        <Select
                            sx={style.select}
                            labelId="test-select-label"
                            id="assignment"
                            value={timesheetData.assignment}
                            label="Contract"
                            onChange={e=>handleSelectContract(e.target.value)}
                        >
                            {optContracts.map((item, idx) => {
                                return(
                                    <MenuItem key={"optContracts"+idx} value={item.value}>{item.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </div>
                
                <div className='ts-entry-row'>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Date"
                            value={timesheetData.date}
                            onChange={e=>getCurrentAssignments(timesheetData.tutor, e)}
                            renderInput={params => <TextField {...params} sx={style.date}/>}
                        />
                    </LocalizationProvider>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={timesheetData.absent}
                                onChange={e=>handleAbsent(e)}
                                size="large"
                            />
                        }
                        label="Absent"
                        style={style.absent}
                    />
                </div>

                <div className="ts-entry-row">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                            label="Time In"
                            format="HH:mm"
                            value={timesheetData.time_in}
                            onChange={e => handleTimeIn(e)}
                            renderInput={(params) => <TextField {...params}
                            sx={style.time}/>}
                        />

                        <TimePicker
                            label="Time Out"
                            format="HH:mm"
                            value={timesheetData.time_out}
                            onChange={e => handleTimeOut(e)}
                            renderInput={(params) => <TextField {...params} 
                            sx={style.time}/>}
                        />
                    </LocalizationProvider>

                    <FormControl
                        variant="outlined"
                        margin={"dense"}
                        style={style.break}
                    >
                        <InputLabel id="test-select-label">Break:</InputLabel>
                        <Select
                            sx={{ color: 'black', width: '15ch' }}
                            labelId="test-select-label"
                            id="break_duration"
                            value={timesheetData.break_duration}
                            label="Break"
                            onChange={e=>handleSelectBreak(e.target.value)}
                        >
                            {breakTimes.map((item, idx) => {
                                return(
                                    <MenuItem key={"ts_breaks"+idx} value={item.value}>{item.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    
                    <TextField
                        sx={style.hrsWorked}
                        id="hours_worked"
                        label="Hours Worked"
                        variant="filled"
                        onChange={e => handleTimesheetData(e.target.value, 'hours_worked')}
                        value={timesheetData.hours_worked}
                    />
                </div>

                <div className='ts-entry-row'>
                    <TextField
                        sx={style.notes}
                        id="description"
                        label="Notes"
                        variant="filled"
                        onChange={e => handleTimesheetData(e.target.value, 'description')}
                        value={timesheetData.description}
                    />
                </div>
                
                {!update ?
                    <div>
                        <Button sx={{ m: 1, width: '20ch' }} variant="outlined" onClick={handleSubmit}>Submit</Button>
                        <Button sx={style.button} variant="outlined" onClick={handleClose}>Cancel</Button>
                    </div>
                :
                    <div>
                        <Button sx={{ m: 1, width: '20ch' }} variant="outlined" onClick={modifyExisting}>Modify</Button>                
                        <Button sx={{ m: 1, width: '20ch' }} variant="outlined" onClick={handleClose}>Cancel</Button>
                    </div>
                }
            </Box>
        </Modal>
    )
}

export default CreateTimesheetModal;