import React, {useState, useEffect} from 'react';
import TimesheetSummary from './TimesheetSummary.js';
import TableTimesheet from './TableTimesheet.js';
// import PropTypes from 'prop-types';
import { 
    MenuItem, InputLabel, FormControl,
    Select, TextField, Radio,
    RadioGroup, FormControlLabel, Button,
} from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreateTimesheetModal from '../create/CreateTimesheetModal.js';
import ConfirmationModal from '../create/ConfirmationModal.js'
import backendApi from '../../api/BackendApi.js';
import moment from 'moment' // 292.3K (gzipped: 71.6K)
import '../../css/Tutors.css';

const ViewTimeSheets = ({user=null}) => {
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [open, setOpen] = useState(false);
    const [update, setUpdate] = useState(false);
    const [timeSheet, setTimeSheet] = useState([])
    const [tutor, setTutor] = useState('');    
    const [pay, setPay] = useState(0);
    const [rate, setRate] = useState(0);
    const [hours, setHours] = useState(0);
    const [optTutors, setOptTutors] = useState(['']);
    const [month, setMonth] = useState(new Date());
    const [day, setDay] = useState(new Date());
    const [viewSelection, setViewSelection] = useState('RADIO_DAY');
    const [confirmed, setConfirmed] = useState('');
    const [timesheetData, setTimesheetData] = useState({
        id: 0,
        tutor: '',
        date: new Date(),
        time_in: new Date().setHours(10, 0, 0),
        time_out: new Date().setHours(12, 0, 0),
        absent: false,
        description: '',
        break_duration: '',
        hours_worked: 0,
        active: 0,
        assignment: 0,
    });


    const desktop = useMediaQuery('(min-width:450px)');

    const styles = {
        dates: {
            width: desktop ? '15%' : '33%',
            mt: '1ch',
        },

        dateMonth: {
            width: desktop ? '15%' : '33%',
            mt: '1ch',
        },

        radioGroup: {
            mt: 1, mb: 1,
        },

        tutorContainer: {
            width: desktop ? '20%' : '50%',
            mb: '1ch',
            mr: '2ch',
        },

        tutor: { 
            width: desktop ? '80%%' : '90%',
            mr: '2ch',
        },

        selectDate: {
            width: '35%',
        },

        button: { m: 1, width: '20%'},

        createButton: { mt: 2, mb: 1, ml: 2, width: '20ch'},

        confirmButton: { mt: 2, mb: 1, width: '25ch'},

        buttonAdd: {
            m: 2, 
            width: '30ch',
            height: '5ch',},
    };

    const columns_admin = [
        { id: 'date', label: 'Date', minWidth: 100 },
        { id: 'name', label: 'Name', minWidth: 150 },
        { id: 'time_in', label: 'In', minWidth: 50 },
        { id: 'time_out', label: 'Out', minWidth: 50 },
        { id: 'break_duration', label: 'Break', minWidth: 40 },
        { id: 'hours_worked', label: 'Hours', minWidth: 40 },
        { id: 'school', label: 'School', minWidth: 40 },
        { id: 'description', label: 'Description', minWidth: 200 },
        { id: 'select', label: 'Edit', minWidth: 50 },
        { id: 'delete', label: 'Delete', minWidth: 50 },
    ];

    const columns_tutor = [
        { id: 'date', label: 'Date', minWidth: 100 },
        { id: 'time_in', label: 'In', minWidth: 50 },
        { id: 'time_out', label: 'Out', minWidth: 50 },
        { id: 'break_duration', label: 'Break', minWidth: 40 },
        { id: 'hours_worked', label: 'Hours', minWidth: 40 },
        { id: 'school', label: 'School', minWidth: 100 },
        { id: 'description', label: 'Description', minWidth: 200 },
    ];

    useEffect(() => {
        if (user !== null) {
            if (user.role === 'ROLE_ADMIN') {
                const tempTutors = [''];

                let params = {
                    day: moment().format("YYYY-MM-DD"),
                };

                backendApi.get("/timesheet/daily", params)
                    .then(data => {
                        data.sort((a,b) => {
                            return new Date(b.date) - new Date(a.date);
                        });
                        setTimeSheet(data);
                    })
                    .catch((error) => {
                        console.log(error.code)
                        if (error.code === 'permission-denied') {
                            // setMsg("Insufficient permission");
                        }
                    });

                backendApi.get("/profile/list/")
                    .then(data => {
                        data.map((item, idx) => (
                            tempTutors.push({label: item.name, value: item.id})
                        ));
                        setOptTutors(tempTutors);
                    })
                    .catch((error) => {
                        console.log(error.code)
                        if (error.code === 'permission-denied') {
                            // setMsg("Insufficient permission");
                        }
                    });
            } else {
                setTutor(user.id);
                setViewSelection('RADIO_MONTH');
                let data = {
                    tutor: user.id,
                    period: moment().format("YYYY-MMM")
                };
                backendApi.get("/timesheet/monthly", data)
                    .then(data => {
                        setTimeSheet(data.time_sheet.sort((a, b) => {
                            return new Date(b.date) - new Date(a.date)
                        }));
                        setHours(data.hours);
                        setPay(data.pay);
                        setRate(data.rate);
                        setConfirmed(data.confirmed);
                    })
                    .catch((error) => {
                        console.log(error.message)
                        if (error.code === 'permission-denied') {
                        }
                    });
            }
        }
    }, [user]);

    const getDailyTimesheet = (da) => {
        let temp = moment(da).format("YYYY-MM-DD");
        backendApi.get("/timesheet/daily", {day: temp})
            .then(data => {
                data.sort((a,b) => {
                    return new Date(b.date) - new Date(a.date);
                });
                setTimeSheet(data);
            })
            .catch((error) => {
                console.log(error.code)
                if (error.code === 'permission-denied') {
                    // setMsg("Insufficient permission");
                }
            });
    }

    const getMonthlyTimesheet = (tu, mo) => {        
        let data = {
            tutor: tu,
            period: moment(mo).format("YYYY-MMM")
        };
        backendApi.get("/timesheet/monthly", data)
            .then(data => {                        
                setTimeSheet(data.time_sheet.sort((a, b) => {
                    return new Date(b.date) - new Date(a.date)
                }));
                setHours(data.hours);
                setPay(data.pay);
                setRate(data.rate);
                setConfirmed(data.confirmed);
            })
            .catch((error) => {
                console.log(error)
                if (error.code === 'permission-denied') {
                    // setMsg("Insufficient permission");
                }
            });
    }

    const handleSelectTutor = (value) => {
        setTutor(value);
        getMonthlyTimesheet(value, month);
    }

    const handleSelectMonth = (period) => {
        setMonth(period);
        if((tutor !== 0 && (tutor !== ''))) {
            getMonthlyTimesheet(tutor, period);
        }
    }

    const handleSelectDay = (temp) => {
        setDay(temp);
        getDailyTimesheet(temp);
    }

    const handleSelectView = (view) => {
        setViewSelection(view);
        if (view === 'RADIO_DAY') {
            getDailyTimesheet(day)
        } else {
            setTimeSheet([]);
            setTutor('');
            setMonth(new Date());
            setHours(0);
            setPay(0);
            setRate(0);
        }
    }
    
    const handleTableUpdate = (dateInput) => {
        setUpdate(false);
        setOpen(false);
        setTimesheetData({
            id: 0,
            tutor: '',
            day: new Date(),
            time_in: new Date().setHours(10, 0, 0),
            time_out: new Date().setHours(12, 0, 0),
            absent: false,
            description: '',
            break_duration: '',
            hours_worked: 0,
            active: 0,
        });

        if (viewSelection === 'RADIO_DAY') {
            getDailyTimesheet(dateInput)
        } else {
            handleSelectTutor(tutor);
        }
    };

    const handleDeleteTimeEntry = (params) => {
        setOpen(false);
        const data={id: params.id};
        backendApi.delete("/timesheet/", data)
            .then(result => {
                if (result) {
                    handleTableUpdate(params.date);
                } else {
                    // setStatusMsg('Delete fail.');
                    // setOpen(true);
                    console.log("error")
                }
                // setOpen(true);
            })
            .catch(error => {
                console.log(error.code);
            });
    }

    const handleClose = () => {
        setUpdate(false);
        setOpen(false);
        setTimesheetData({
            id: 0,
            tutor: '',
            day: new Date(),
            time_in: new Date().setHours(10, 0, 0),
            time_out: new Date().setHours(12, 0, 0),
            absent: false,
            description: '',
            break_duration: '',
            hours_worked: 0,
            active: 0,
        });
    }

    const handleSelect = (param) => {
        let clone = {...param}
        clone.date = moment(param.date)
        clone.time_in = new Date(param.date + "T" + param.time_in)
        clone.time_out = new Date(param.date + "T" + param.time_out)
        switch (parseFloat(param.break_duration)) {
            case 0:
                clone.break_duration = 0;
                break;
            case .25:
                clone.break_duration = 15;
                break;
            case .5:
                clone.break_duration = 30;
                break;
            case 0.75:
                clone.break_duration = 45;
                break;
            case 1.0:
                clone.break_duration = 60;
                break;
            case 1.25:
                clone.break_duration = 75;
                break;
            case 1.5:
                clone.break_duration = 90;
                break;
            case 2:
                clone.break_duration = 120;
                break;
            default:
                break;
        }
        
        setTimesheetData(clone);
        setUpdate(true)
        setOpen(true);
    }

    const handleCloseConfirmation = () => {
        setOpenConfirmation(false);
    }

    const handleOpenConfirmation = () => {
        if (confirmed === 'false') {
            setOpenConfirmation(true);
        }
    }

    const createNew = () => {
        let clone = {...timesheetData};        
        clone.date = moment(timesheetData.date).format("YYYY-MM-DD");
        clone.time_in = moment(timesheetData.time_in).format("HH:mm");
        clone.time_out = moment(timesheetData.time_out).format("HH:mm");
        clone.break_duration = timesheetData.break_duration/60.0;
        clone.hours_worked = parseFloat(timesheetData.hours_worked);
        clone.description = timesheetData.absent ?  "Absent: " + timesheetData.description : timesheetData.description;
        delete clone.active;

        backendApi.post("/timesheet/", clone)
            .then(result => {
                if (result > 0) {
                    localStorage.removeItem('time_in');
                    localStorage.removeItem('time_out');
                    localStorage.removeItem('hours_worked');
                    localStorage.removeItem('break');
                    handleTableUpdate(clone.date);
                } else {
                    // setStatusMsg("Save failed");
                    // setOpenStatusModal(true);
                }
            })
            .catch(error => {
                console.log(error.code);
            });
    }

    const modifyExisting = () => {
        let clone = {...timesheetData};        
        clone.date = moment(timesheetData.date).format("YYYY-MM-DD");
        clone.time_in = moment(timesheetData.time_in).format("HH:mm");
        clone.time_out = moment(timesheetData.time_out).format("HH:mm");
        clone.break_duration = timesheetData.break_duration/60.0;
        clone.hours_worked = parseFloat(timesheetData.hours_worked);
        clone.description = timesheetData.absent ?  "Absent: " + timesheetData.description : timesheetData.description;
        backendApi.update("/timesheet/", clone)
            .then(result => {
                if (result === 1) {
                    handleTableUpdate(clone.date);
                } else {
                    // setStatusMsg("Record modification failed.");
                    // setOpenStatusModal(true);
                }
            })
            .catch(error => {
                console.log(error.code);
            });
    }

    const handleOpenNew = () => {
        setOpen(true);
    }

    const createPdf = () => {
        if (tutor !== 0) {
            const data = {
                tutor: tutor,
                period: moment(month).format("YYYY-MMM"),
                file: "payroll_" + moment(month).format("YYYY-MMM") + ".pdf"
            };
            backendApi.download("/report/create_tutor_timesheet_pdf", data);
        }
    }

    return (
        <div className="body-container">
            {user.role === 'ROLE_ADMIN' ?
                <div className="filter-attendance">

                    <CreateTimesheetModal
                        open_modal={open}
                        update={update}
                        handleClose={handleClose}
                        timesheetData={timesheetData}
                        setTimesheetData={setTimesheetData}
                        createNew={createNew}
                        modifyExisting={modifyExisting}
                        optTutors={optTutors}
                    />

                    <FormControl sx={styles.radioGroup} >
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            value={viewSelection}
                            onChange={(e) => handleSelectView(e.target.value)}
                            row={true}
                        >
                            <FormControlLabel value="RADIO_DAY" control={<Radio />} label="Daily" />
                            <FormControlLabel value="RADIO_MONTH" control={<Radio />} label="Monthly" />
                        </RadioGroup>
                    </FormControl>

                    {viewSelection === 'RADIO_MONTH' ?
                        <FormControl
                            style={styles.tutorContainer}
                            variant="outlined"
                            margin={"dense"}
                        >
                            <InputLabel id="test-select-label">Tutor</InputLabel>
                            <Select
                                sx={styles.tutor}
                                labelId="test-select-label"
                                id="school"
                                value={tutor}
                                label="School"
                                onChange={e=>handleSelectTutor(e.target.value)}
                            >
                                {optTutors.map((item, idx) => {
                                    return(
                                        <MenuItem key={"ts_tutors_"+idx} value={item.value}>{item.label}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    :
                        null
                    }

                    {viewSelection === 'RADIO_MONTH' ?
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Month"
                                views={['month','year']}
                                value={month}
                                onChange={newValue => { handleSelectMonth(newValue); }}
                                renderInput={params => <TextField {...params} sx={styles.dateMonth}/>}
                                
                            />
                        </LocalizationProvider>
                    :
                        <LocalizationProvider dateAdapter={AdapterDateFns} >
                            <DatePicker
                                label="Date"
                                value={day}
                                onChange={newValue => { handleSelectDay(newValue)}}
                                renderInput={params => <TextField {...params} sx={styles.dates}/>}
                            />
                        </LocalizationProvider>
                    }

                    {desktop ?
                        <Button
                            sx={styles.buttonAdd}
                            variant="outlined"
                            onClick={handleOpenNew}
                        >
                            <div className='btn-add-icon'>
                                <FontAwesomeIcon icon={faPlusCircle} />
                            </div>
                            <div> Add Timesheet</div>
                        </Button>
                    :
                        null
                    }


                    {viewSelection === 'RADIO_MONTH' ?
                        <div>
                            <Button
                                sx={styles.button}
                                onClick={createPdf}
                                variant="outlined"
                            >
                                Create PDF
                            </Button>

                            <TimesheetSummary hours={hours} rate={rate} pay={pay}/>
                        </div>
                    :
                        null
                    }
                </div>
            :
                <div>
                    <ConfirmationModal
                        open_modal={openConfirmation}
                        handleClose={handleCloseConfirmation}
                        month={month}
                        user={user}
                    />

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Month"
                            views={['month','year']}
                            value={month}
                            onChange={newValue => { handleSelectMonth(newValue); }}
                            renderInput={params => <TextField {...params} sx={{ mt: 3, width: 175 }}/>}
                            
                        />
                    </LocalizationProvider>

                    <div className='break-selector'>
                        <Button 
                            sx={styles.createButton}
                            onClick={createPdf}
                        >
                            Create PDF
                        </Button>

                        {confirmed === 'false' ?
                            <Button 
                                sx={styles.confirmButton}
                                onClick={handleOpenConfirmation}
                            >
                                Confirm Timesheet
                            </Button>
                        : 
                            null
                        }
                    </div>

                    <TimesheetSummary hours={hours} rate={rate} pay={pay}/>
                </div>
            }

            <TableTimesheet
                data={timeSheet}
                columns={user.role === 'ROLE_ADMIN' ? columns_admin : columns_tutor}
                handleSelect={handleSelect}
                handleDeleteTimeEntry={handleDeleteTimeEntry}
            />
        </div>
    );
}

// ViewTimeSheets.propTypes = {
//     name: PropTypes.string
//   };

export default ViewTimeSheets;