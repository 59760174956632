import React, {useState, useEffect} from 'react';
import {
    Paper, Table, TableBody, TableCell,
    TableContainer, TableHead, TablePagination,
    TableRow, TextField, Button,
} from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';

import backendApi from '../../api/BackendApi.js';
import moment from 'moment' // 292.3K (gzipped: 71.6K)
import '../../css/Tutors.css';

const CreatePayment = () => {
    const [payroll, setPayroll] = useState([])
    const [startDate, setStartDate] = useState(moment(new Date()).startOf('month'));
    const [totalPayOut, setTotalPayOut] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const desktop = useMediaQuery('(min-width:450px)');

    const endDate = moment(new Date()).endOf('month');

    const styles = {
        tableCell: {
          padding: "5px 16px"
        },
        
        tableContainer: {
            maxHeight: 440,
            direction: "column",
            justifyContent: "center",
            alignItems: "center"
        },

        page: {
            margin: '5ch auto',
            width: '80%',
            overflow: 'hidden',
            justifyContent: "center",
            alignItems: "center",
            direction: "column"
        },
    
        dates: {
            width: desktop ? '22%' : '30%',
            m: 'auto',
        },
      };

    const columns = [
      { id: 'name', label: 'Name', minWidth: 150 },
      { id: 'hours', label: 'Hours', minWidth: 50 },
      { id: 'rate', label: 'Rate', minWidth: 50 },
      { id: 'total', label: 'Pay', minWidth: 50 },
      { id: 'confirmed', label: 'Confirmed', minWidth: 50 },
      { id: 'paid', label: 'Paid', minWidth: 50 },
    ];
    
    useEffect(() => {
        const data = {
            month: moment(new Date()).format("YYYY-MMM"),
        };
        backendApi.get("/timesheet/payroll", data)
            .then(data => {
                setPayroll(data.indiv_summary);
                setTotalPayOut(data.emc_total_payroll);
            })
            .catch((error) => {
                console.log(error.code)
                if (error.code === 'permission-denied') {
                    // setMsg("Insufficient permission");
                }
            });
    }, [], [payroll, totalPayOut])

    const handleSelectMonth = (e) => {
        setStartDate(e);

        const data = {
            month: moment(e).format("YYYY-MMM"),
        };

        backendApi.get("/timesheet/payroll", data)
            .then(data => {
                setPayroll(data.indiv_summary);
                setTotalPayOut(data.emc_total_payroll);
            })
            .catch((error) => {
                console.log(error.code)
                if (error.code === 'permission-denied') {
                    // setMsg("Insufficient permission");
                }
            });
        }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
         setPage(0);
    };

    const handleCheckPaid = (idx) => {
        let index = idx + (page * 10);
        let temp = {};
        temp['month'] = moment(startDate).format("YYYY-MM-DD");
        temp['amount'] = parseFloat(payroll[index]['total']);
        temp['hours'] = parseFloat(payroll[index]['hours']);
        temp['rate'] = parseFloat(payroll[index]['rate']);
        temp['emp_id'] = payroll[index]['tutor'];
        temp['description'] = "Test";
        temp['timestamp'] = moment().format("YYYY-MM-DD");

        backendApi.post("/payment/", temp)
        .then(data => {
            if (data !== false) {
                // let temp2 = Object.assign({}, payroll);
                // var allItems = JSON.parse(JSON.stringify(payroll));
                let temp2 = [...payroll]

                let index = payroll.findIndex(x => x.tutor === data);
                temp2[index]['paid'] = 1;
                setPayroll(temp2);
            }
        })
        .catch((error) => {
            console.log(error.code)
            if (error.code === 'permission-denied') {
            }
        });
    }

    const createCsv = () => {
        const file = "payroll_" + moment(startDate).format("YYYYMMDD") 
            + "_" + moment(endDate).format("YYYYMMDD") + ".csv";
        
            const data = {
                month: moment(startDate).format("YYYY-MMM"),
                file: file,
            };
            backendApi.download("/report/create_payroll_csv", data);
    }

    return (
        <div className="body-container">
            <h3> Payroll </h3>

            <div>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Select"
                        views={['month','year']}
                        value={startDate}
                        onChange={newValue => { handleSelectMonth(newValue); }}
                        renderInput={params => <TextField {...params} sx={styles.dates}/>}
                    />
                </LocalizationProvider>
            </div>

            <Button sx={{ m: 1, width: '30ch' }} onClick={createCsv}>
                Download Excel
            </Button>
            
            <div className='total-payout'> Total = ${totalPayOut} </div>
            
            <Paper sx={styles.page}>
                {/* <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    From: {moment(startDate).format('YYYY-MM-DD')} To: {moment(endDate).format('YYYY-MM-DD')}
                </Typography> */}
                <TableContainer sx={styles.tableContainer}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                <TableCell
                                    key={'pr_'+column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {payroll
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, idx) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={'pr_body_'+row.name}>
                                    {columns.map((column) => {
                                        const value = row[column.id];
                                        return (
                                        <TableCell key={column.id} align={column.align} sx={styles.tableCell}>
                                            {column.id === 'paid' ?
                                                !value ? <Button sx={{ m: 1, width: '5ch' }} variant="outlined" onClick={()=>handleCheckPaid(idx)}>Pay</Button> : <div>PAID</div> 
                                                : column.format && typeof value === 'number'?
                                                    column.format(value)
                                                    : value}
                                            {/* Have 3 states: PAY, PENDING, PAID*/}
                                        </TableCell>
                                        );
                                    })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={payroll.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}

// ViewPayroll.propTypes = {
//     name: PropTypes.string
//   };

export default CreatePayment;