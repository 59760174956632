import React from 'react';
import {Button, Box, Typography, Modal} from '@mui/material';
import '../css/Modal.css';

const style = {
    box: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    },
};

const Message = ({message='', open=false, btn1=null, btn2=null,
    handleClose=null, handleBtn1=null, handleBtn2=null
}) => {
    return(
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style.box}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {message}
                </Typography>

                {btn1 !== null ?
                    <Button 
                        sx={{ m: 1, width: '15ch' }}
                        variant="outlined"
                        onClick={handleBtn1}
                    >
                        {btn1}
                    </Button>                    
                : null}
                
                {btn2 !== null ?
                    <Button 
                        sx={{ m: 1, width: '15ch' }}
                        variant="outlined"
                        onClick={handleBtn2}
                    >
                        {btn2}
                    </Button>                    
                : null}
            </Box>
        </Modal>
    )
}

export default Message;