import React, {useEffect, useState} from 'react';
import {Button, TextField, FormControl, InputLabel, Select, MenuItem,
    FormControlLabel, Checkbox} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation } from "react-router-dom";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import moment from 'moment' // 292.3K (gzipped: 71.6K)
import Message from '../Message.js';
import backendApi from '../../api/BackendApi.js';
import '../../css/Tutors.css';

const CreateTimeSheet = ({user=null}) => {
    let time1 = new Date();
    time1.setHours(10, 0, 0);
    let time2 = new Date();
    time2.setHours(12, 0, 0);
    const [adjDate, setAdjDate] = useState(Date());
    const location = useLocation();
    const [optContracts, setOptContracts] = useState([]);
    const [assignment, setAssignment] = useState('')
    const [tutor, setTutor] = useState('');
    const [timeIn, setTimeIn] = useState(time1);
    const [timeOut, setTimeOut] = useState(time2);
    const [absent, setAbsent] = useState(false);
    const [description, setDescription] = useState("");
    const [breakDuration, setBreakDuration] = useState("");
    const [message, setMessage] = useState("");
    const [hoursWorked, setHoursWorked] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const handleClose = () => setOpen(false);
    const handleCloseDeleteModal = () => setOpenDelete(false);
    
    const breakTimes = [
        {label: 'None', value: 0},
        {label: '15 min', value: 15},
        {label: '30 min', value: 30},
        {label: '45 min', value: 45},
        {label: '1 hr', value: 60},
        {label: '1 hr 15 min', value: 75},
        {label: '1 hr 30 min', value: 90},
        {label: '2 hr', value: 120},
    ];

    const desktop = useMediaQuery('(min-width:450px)');

    const style = {
        box: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        },
    
        time: {
            width: '15ch',
        },
    
        date: {
            width: '10ch',
        },
    
        loco: {
            display: 'inline-flex',
        },
    
        selectContainer: {
            width: desktop ? '40%' : '80%',
            m: 'auto',
        },
    
        select: {
            color: 'black',
            width: '100%',
            mb: '2ch',
        },
    
        notes: {
            width: desktop ?'50%' : '80%',
            mb: '2ch',
        },
        dates: {
            width: desktop ? '12%' : '25%',
            mt: '1ch',
        },
    };

    useEffect(() => {

        const todaysDate = moment().format('YYYYMMDD');
        let tempTimeIn = localStorage.getItem('timeIn');
        let tempTimeOut = localStorage.getItem('timeOut');

        if (tempTimeIn !== null) {
            if (moment(tempTimeIn).format('YYYYMMDD') !== todaysDate) {
                localStorage.removeItem('timeIn')
            } else {
                setTimeIn(tempTimeIn);
            }
        }

        if (tempTimeOut !== null) {
            if (moment(tempTimeOut).format('YYYYMMDD') !== todaysDate) {
                localStorage.removeItem('timeOut')
            } else {
                setTimeOut(tempTimeOut);
            }
        }

        if (localStorage.getItem('hoursWorked') !== null) { setHoursWorked(localStorage.getItem('hoursWorked')); }
        if (localStorage.getItem('break') !== null) { setBreakDuration(localStorage.getItem('break')); }

        if (user !== null) {
            setTutor(user.id);            

            let tempContracts = [];
            user.assignments.map((item, idx) => (
                tempContracts.push({label: item.school, value: item.assignment})
            ));
            setOptContracts(tempContracts);
            setAssignment(user.assignments[0].assignment);
        }
    }, [user, location]);
  
    const handleSubmit = () => {

        if (!absent && (parseFloat(hoursWorked) <= 0)) {
            setMessage('Times are not valid');
            setOpen(true);
        } else if (parseFloat(hoursWorked) > 10) {
            setMessage("Hours worked > 10. Re-enter or get Admin approval.");
            setOpen(true);
        } else {
            const data = {
                tutor: tutor,
                date: moment(adjDate).format("YYYY-MM-DD"),
                time_in: moment(timeIn).format("HH:mm"),
                time_out: moment(timeOut).format("HH:mm"),
                break_duration: breakDuration/60.0,
                hours_worked: parseFloat(hoursWorked),
                description: absent ?  "Absent: " + description : description,
                assignment: assignment,
            };

            backendApi.post("/timesheet/", data)
                .then(result => {
                    if (result === 1) {
                        setTimeIn('Sun Nov 06 2022 00:00:00 GMT-0400 (Eastern Daylight Time)');
                        setTimeOut('Sun Nov 06 2022 00:00:00 GMT-0400 (Eastern Daylight Time)');
                        setHoursWorked(0);
                        setDescription("");
                        localStorage.removeItem('timeIn');
                        localStorage.removeItem('timeOut');
                        localStorage.removeItem('hoursWorked');
                        localStorage.removeItem('break');
                        setMessage('Data saved.')
                    } else {
                        setMessage('Data not saved.')
                    }
                    setOpen(true);
                })
                .catch(error => {
                    console.log(error.code);
                });
        }
    }

    const handleDelete = () => {
        setMessage('Are you sure you want to delete?')
        setOpenDelete(false);
        const data = {id: location.state.id}
        backendApi.delete("/timesheet/", data)
            .then(result => {
                if ((result === 1) || (result === true)) {
                    setMessage("Delete successful.")
                } else {
                    setMessage("Delete failed.")
                }
                setOpen(true);
                setTimeIn('');
                setTimeOut('');
                setDescription('');
                setHoursWorked(0);
                setBreakDuration(0);
            })
            .catch(error => {
                console.log(error.code);
            });
    }

    const handleTimeIn = (e) => {
        if(!absent) {
            setTimeIn(e);
            localStorage.setItem('timeIn', e);
            if(timeOut !== '') {
                autoTimeElapsed(e, timeOut, breakDuration);
            }
        }
    }

    const handleTimeOut = (e) => {
        if(!absent) {
            setTimeOut(e);
            localStorage.setItem('timeOut', e)
            if(timeIn !== '') {
                autoTimeElapsed(timeIn, e, breakDuration);
            }
        }
    }

    const autoTimeElapsed = (tIn, tOut, tBreak) => {
        tIn = moment(tIn);
        tOut = moment(tOut);
        let duration = moment.duration(tOut.diff(tIn));
        let hours = duration.asHours();
        let fractional = hours % 1;
        let m = (Math.round((fractional*60)/15) * 15) % 60;
        let revisedElapsed = parseInt(hours, 10) + (m/60.0);
        let hw = revisedElapsed - (tBreak/60.0);

        if(!isNaN(hw)) {
            setHoursWorked(hw);
            localStorage.setItem('hoursWorked', hw);
        }
    }

    const handleSelectBreak = (value) => {
        if(!absent) {
            localStorage.setItem('break', value);
            setBreakDuration(value);
            autoTimeElapsed(timeIn, timeOut, value);
        }
    }

    const handleAbsent = (e) => {
        setAbsent(e.target.checked);
        if (e.target.checked) {
            setTimeIn(moment("12:00:00 AM", "h:mm:ss A"))
            setTimeOut(moment("12:00:00 AM", "h:mm:ss A"))
            setHoursWorked(0);
            setBreakDuration(0);
        }
    }

    const handleSelectDay = (newDay) => {
        // Create moment objects for your dates
        const startDate = moment(newDay, "YYYY-MM-DD");
        const endDate = moment(moment(), 'YYYY-MM-DD');

        // Calculate the difference
        const duration = moment.duration(endDate.diff(startDate));

        // Get the difference in weeks
        const daysDifference = duration.asDays();

        //Keep the selected date within a range of current date
        if ((daysDifference >= 0) && (daysDifference <= 14)) {
            setAdjDate(newDay);
        }
    }

    return (
        <div className="body-container">
            <h3> Timesheet Entry </h3>

            <Message
                message={message}
                open={open}
                btn1={'OK'}
                handleClose={handleClose}
                handleBtn1={handleClose}
            />

            <Message
                message={message}
                open={openDelete}
                btn1={'OK'}
                btn2={'Cancel'}
                handleClose={handleCloseDeleteModal}
                handleBtn1={handleDelete}
                handleBtn2={handleCloseDeleteModal}
            />

            <LocalizationProvider dateAdapter={AdapterDateFns} >
                <MobileDatePicker
                    label="Date"
                    value={adjDate}
                    onChange={newValue => { handleSelectDay(newValue)}}
                    renderInput={params => <TextField {...params} sx={style.dates}/>}
                />
            </LocalizationProvider>

            <div className="attendance-group">
                <div className='sign-in-time-group'>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <div className='side-by-side'>
                            <div className='times'>
                                <div> Time In: </div>
                                <TimePicker
                                    format="HH:mm"
                                    value={timeIn}
                                    onChange={e => handleTimeIn(e)}
                                    renderInput={(params) => <TextField {...params}
                                    sx={style.time}/>}
                                />
                            </div>

                            <div className='times'>
                                <div> Time Out: </div>
                                <TimePicker
                                    format="HH:mm"
                                    value={timeOut}
                                    onChange={e => handleTimeOut(e)}
                                    renderInput={(params) => <TextField {...params} 
                                    sx={style.time}/>}
                                />
                            </div>
                        </div>
                    </LocalizationProvider>

                    <div className='side-by-side'>
                        <div className='break-selector'>
                            <FormControl
                                variant="outlined"
                                margin={"dense"}
                                style={{ marginBottom: 5}}
                            >
                                <InputLabel id="test-select-label">Break:</InputLabel>
                                <Select
                                    sx={{ color: 'black', width: '20ch' }}
                                    labelId="test-select-label"
                                    id="breakDuration"
                                    value={breakDuration}
                                    label="Break"
                                    onChange={e=>handleSelectBreak(e.target.value)}
                                >
                                    {breakTimes.map((item, idx) => {
                                        return(
                                            <MenuItem key={"ts_breaks"+idx} value={item.value}>{item.label}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </div>

                        <div className='break-selector'>
                            <TextField
                                sx={{ m: 2, width: '15ch' }}
                                id="hoursWorked"
                                label="Hours Worked"
                                variant="filled"
                                onChange={e => setHoursWorked(e.target.value)}
                                value={hoursWorked}
                            />
                        </div>
                    </div>

                    <div>  
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={absent}
                                    onChange={e=>handleAbsent(e)}
                                    size="large"
                                />
                            }
                            label="Absent"
                        />
                    </div>
                    

                <div>
                        <FormControl    
                            variant="outlined"
                            margin={"dense"}
                            style={style.selectContainer}
                        >
                            <InputLabel id="test-select-label">School</InputLabel>
                            <Select
                                sx={style.select}
                                labelId="test-select-label"
                                id="assignment"
                                value={assignment}
                                label="Contract"
                                onChange={e=>setAssignment(e.target.value)}
                            >
                                {optContracts.map((item, idx) => {
                                    return(
                                        <MenuItem key={"optContracts"+idx} value={item.value}>{item.label}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </div>

                    <div>
                        <TextField
                            sx={style.notes}
                            id="description"
                            label="Notes"
                            variant="filled"
                            onChange={e => setDescription(e.target.value)}
                            value={description}
                        />
                    </div>
                </div>
            </div>
            
            <div>
                <Button sx={{ m: 1, width: '20ch' }} variant="outlined" onClick={handleSubmit}>Submit</Button>
            </div>
        </div>
    )
}

export default CreateTimeSheet;