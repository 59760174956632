import React from 'react';
// import PropTypes from 'prop-types';
import { 
    Paper, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow
} from "@mui/material";
import '../../css/Tutors.css';

const TimesheetSummary = ({hours=null, rate=null, pay=null}) => {
    return (
        <div className="body-container">
            <Paper sx={{ margin: '1ch auto', width: '80%', overflow: 'hidden', justifyContent: "center", alignItems: "center", direction: "column" }}>
                <TableContainer sx={{ maxHeight: 440, direction: "column", justifyContent: "center", alignItems: "center" }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Hours Worked
                                </TableCell>
                                <TableCell>
                                    Pay Rate
                                </TableCell>
                                <TableCell>
                                    Totay Pay
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow hover role="checkbox" tabIndex={-1} >
                                <TableCell > {hours} hours </TableCell>
                                <TableCell > ${rate} /hr </TableCell>
                                <TableCell > ${pay} </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}

// TimesheetSummary.propTypes = {
//     name: PropTypes.string
//   };

export default TimesheetSummary;