import React, {useState, useEffect} from 'react';
import { Button, } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableContracts from './TableContracts.js';
import CreateContractModal from '../create//CreateContractModal.js'
import backendApi from '../../api/BackendApi.js';
import '../../css/Tutors.css';

const ViewContracts = () => {

    const desktop = useMediaQuery('(min-width:450px)');

    const style = {
        button: {
            mt: 1,
            ml: 5,
            mr: 5,
            width: '30ch',
            height: '5ch',
        },
    };

    const [contracts, setContracts] = useState([]);
    const [open, setOpen] = useState(false);
    const [update, setUpdate] = useState(false);
    const [contractData, setContractData] = useState({
        // id: 0,
        school: '',
        purchase_order: '',
        amount: '',
        contract_num: '',
        description: '',
        start_date: new Date(),
        end_date: new Date(),
        name: '',
    });
    
    useEffect(() => {
        backendApi.get('/contract/all')
            .then(data => {
                if (data.message) {
                    setContracts([]);
                } else {
                    setContracts(data);
                }
            })
            .catch((error) => {
                console.log(error.code)
                if (error.code === 'permission-denied') {
                    // setMsg("Insufficient permission");
                }
            });
    }, [])

    const columns = [
        { id: 'name', label: 'School', minWidth: 150 },
        { id: 'purchase_order', label: 'PO #', minWidth: 100 },
        { id: 'description', label: 'description', minWidth: 150 },
        { id: 'start_date', label: 'Start', minWidth: 100 },
        { id: 'end_date', label: 'End', minWidth: 100 },
        { id: 'edit', label: 'Edit', minWidth: 50, align: 'center' },
        { id: 'report', label: 'Report', minWidth: 50, align: 'center' },
        { id: 'roster', label: 'Roster', minWidth: 50, align: 'center' },
    ];
    
    const handleTableUpdate = () => {
        setUpdate(false);
        setOpen(false);
        backendApi.get("/contract/all/")
            .then((data) => {
                if (data.message) {
                    setContracts([]);
                } else {
                    setContracts(data);
                }
            })
            .catch((error) => {
                console.log(error)
                if (error.code === 'permission-denied') {
                    // setMsg("Insufficient permission");
                }
            });
    };

    const handleContract = (param) => {
        setContractData(param);
        setUpdate(true)
        setOpen(true);
    }

    // Called when create/update modal closes
    const handleClose = () => {
        setUpdate(false);
        setOpen(false);
        setContractData({
            school: '',
            purchase_order: '',
            amount: '',
            contract_num: '--',
            description: '',
            start_date: new Date(),
            end_date: new Date(),
            name: '',
        });
    }

    return (
        <div className="body-container">

            <CreateContractModal
                open_modal={open}
                update={update}
                contractData={contractData}
                handleOk={handleTableUpdate}
                handleClose={handleClose}
                setContractData={setContractData}
            />

            <div className='title-bar'>
                Contracts

                {desktop ?
                    <Button
                        sx={style.button}
                        variant="outlined"
                        onClick={() => setOpen(true)}
                    >
                        <div className='btn-add-icon'>
                            <FontAwesomeIcon icon={faPlusCircle} />
                        </div>
                        <div> Add Contract</div>
                    </Button>
                :
                    null
                }
            </div>

            <TableContracts
                data={contracts}
                columns={columns}
                pathname={'create-contract'}
                handleContract={handleContract}
                // handleDeleteEmployee={handleDeleteEmployee}
            />
        </div>
    );
}

export default ViewContracts;