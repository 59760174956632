import React, {useState, useEffect} from 'react';
import { Button, } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableEmployees from './TableEmployees.js';
import CreateUserModal from '../create//CreateUserModal.js'
import Message from '../Message.js';
import backendApi from '../../api/BackendApi.js';
import '../../css/Tutors.css';

const ViewTutors = () => {

    const desktop = useMediaQuery('(min-width:450px)');

    const style = {
        button: {
            mt: 1,
            ml: 5,
            mr: 5, 
            width: '30ch',
            height: '5ch',
        },
    };

    const [employees, setEmployees] = useState([]);
    const [open, setOpen] = useState(false);
    const [update, setUpdate] = useState(false);
    const [statusMsg, setStatusMsg] = useState('');
    const [openDelete, setOpenDelete] = useState(false);
    const [employeeData, setEmployeeData] = useState({
        id: 0,
        email: '',
        name: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        zipcode: '',
        pay_type: 'HOURLY',
        rate: '',
        active: true,
        bilingual: false,
    });

    const columns = [
      { id: 'name', label: 'Name', minWidth: 150 },
      { id: 'email', label: 'Email', minWidth: 150 },
      { id: 'phone', label: 'Phone', minWidth: 75 },
      { id: 'active',  label: 'Active', minWidth: 50, align: 'center' },
      { id: 'bilingual',  label: 'Bilingual', minWidth: 50, align: 'center' },
      { id: 'select', label: 'Edit', minWidth: 50, align: 'center' },
      { id: 'view', label: 'View', minWidth: 50, align: 'center' },
      { id: 'delete', label: 'Delete', minWidth: 50, align: 'center' },
    ];

    const handleTableUpdate = () => {
        setUpdate(false);
        setOpen(false);
        backendApi.get("/profile/all/")
            .then((data) => {
                if (data.message) {
                    setEmployees([]);
                } else {
                    setEmployees(data);
                }
            })
            .catch((error) => {
                console.log(error)
                if (error.code === 'permission-denied') {
                    // setMsg("Insufficient permission");
                }
            });
    };
    
    useEffect(() => {
        handleTableUpdate();
    }, []);

    const handleEmployee = (param) => {
        setEmployeeData(param);
        setUpdate(true)
        setOpen(true);
    }

    // Called when create/update modal closes
    const handleClose = () => {
        setUpdate(false);
        setOpen(false);
        setEmployeeData({
            email: '',
            name: '',
            phone: '',
            address: '',
            city: '',
            state: '',
            zipcode: '',
            pay_type: 'HOURLY',
            rate: '',
            active: true,
            bilingual: false,
        });
    }

    const handleDeleteEmployee = (id) => {
        setOpenDelete(false);
        const data={id: employeeData.id};
        backendApi.delete("/user/", data)
            .then(result => {
                if (result) {
                    handleTableUpdate();
                } else {
                    // setStatusMsg('Create Fail');
                    // setOpen(true);
                    console.log("error")
                }
                // setOpen(true);
            })
            .catch(error => {
                console.log(error.code);
            });
    }

    const handleCloseDeleteModal = () => setOpenDelete(false);

    const handleOpenDeleteModal = (param) => {
        setEmployeeData(param);
        setStatusMsg(<div><p>Are you sure you want to delete</p><p>{param.name}?</p></div>)
        setOpenDelete(true)
    };

    return (
        <div className="body-container">

            <Message
                message={statusMsg}
                open={openDelete}
                btn1={'OK'}
                btn2={'Cancel'}
                handleClose={handleCloseDeleteModal}
                handleBtn1={handleDeleteEmployee}
                handleBtn2={handleCloseDeleteModal}
            />

            <CreateUserModal
                open_modal={open}
                update={update}
                employeeData={employeeData}
                handleOk={handleTableUpdate}
                handleClose={handleClose}
                setEmployeeData={setEmployeeData}
            />

            <div className='title-bar'>
                Employees

                {desktop ?
                    <Button
                        sx={style.button}
                        variant="outlined"
                        onClick={() => setOpen(true)}
                    >
                        <div className='btn-add-icon'>
                            <FontAwesomeIcon icon={faPlusCircle} />
                        </div>
                        <div> Add Employee</div>
                    </Button>
                :
                    null
                }
            </div>

            <TableEmployees
                data={employees}
                columns={columns}
                handleEmployee={handleEmployee}
                handleDeleteEmployee={handleOpenDeleteModal}
            />
        </div>
    );
}

export default ViewTutors;