import React, {useState} from 'react';
import { Button, TextField} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Message from './Message.js';
import backendApi from '../api/BackendApi.js';
import '../css/Tutors.css'

const LogIn = ({verifyLogin}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [action, setAction] =  useState("login");
    const [showError, setShowError] = useState(false);
    const [errMsg, setErrMsg] = useState("");

    const desktop = useMediaQuery('(min-width:450px)');

    const styles = {
        btn1: {
            mt: 5,
            mb: 5, 
            width: '50%',
            fontWeight: 'bold',
            backgroundColor: "#2a3348",
        },
        
        btnAnchor: {
            width: '60%',
            direction: "column",
            justifyContent: "center",
            alignItems: "center"
        },

        email: {
            width: '80%',
            mt: '3ch',
        },

        password: {
            width: '80%',
            mt: '2ch',
        }
    };

    const handleLogIn = async () => {
        if ((email !== '') && (password !== '')) {
            const data = {
                email: email,
                password: password
            };
            backendApi.post('/user/log-in/', data)
                .then(loginResult =>{
                    if ((loginResult !== null) && (loginResult["message"] === undefined)) {
                        verifyLogin(loginResult);
                    }
                    else {
                        setErrMsg(loginResult["message"]);
                        setShowError(true);
                    }
                })
                .catch(error => {
                    console.log(error);
                })

        }
    }

    const handleSignUp = async () => {
        if ((email !== '') && (password !== '')) {
            const temp = email.toLocaleLowerCase();
            const data = {
                email: temp.trim(),
                password: password,
                oldPassword: oldPassword
            };
            backendApi.update("/user/word/", data)
                .then(u => {
                    setAction('login')
                    console.log(u)
                })
                .catch(error => {
                    console.log(error.code)
                });
        } else {
            setErrMsg("Contact admin to verify account.")
            setShowError(true)
        }
    }
    
    const onKeyUpValue = (event) => {
        if (event.key === "Enter") {
            handleLogIn();
        }
    }
    
    const handleClose = () => {
        setShowError(false);
    }

    return (
        <div className="login-page">
            <div className={desktop ? 'login-card' : 'login-card-mobile'}>
                <h3 className='login-field login-title'> EMC</h3>

                <Message
                    message={errMsg}
                    open={showError}
                    btn1={'OK'}
                    handleClose={handleClose}
                    handleBtn1={handleClose}
                />

                <TextField
                    sx={styles.email}
                    required
                    id="email"
                    label="Email"
                    variant="filled"
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                />

                {action !== 'login' ?
                    <TextField
                        sx={styles.password}
                        required
                        id="oldPassword"
                        label="Old Password"
                        variant="filled"
                        onChange={e => setOldPassword(e.target.value)}
                        value={oldPassword}
                        type="password"
                        onKeyUp={e => onKeyUpValue(e)}
                    />
                    : null
                }

                <TextField
                    sx={styles.password}
                    required
                    id="password"
                    label={action === 'login'?"Password":"New Password"}
                    variant="filled"
                    onChange={e => setPassword(e.target.value)}
                    value={password}
                    type="password"
                    onKeyUp={e => onKeyUpValue(e)}
                />

                {action === 'login' ?
                    <div>
                        <Button sx={styles.btn1} variant="outlined" onClick={handleLogIn}>Login</Button>
                        <div className='select-text'>
                            <Button sx={styles.btnAnchor} onClick={()=>setAction('signup')}>
                                Change Password
                            </Button>
                        </div>
                    </div>
                    :
                    <div>
                        <Button sx={styles.btn1} variant="outlined" onClick={handleSignUp}>Change Password</Button>
                        <div className='select-text'>
                            Already have an account?
                            <Button sx={styles.btnAnchor} onClick={()=>setAction('login')}>
                                Login now
                            </Button>
                        </div>
                    </div>
                }                
            </div>            
        </div>
    )
}

export default LogIn;