import React, {useState} from 'react';
import { 
    Paper, Table, TableBody, TableCell,
    TableContainer, TableHead, TablePagination,
    TableRow, Button, Typography,
} from "@mui/material";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import '../../css/Tutors.css';

const TableTemplate = ({
    data=null,
    columns=null,
    pathname=null,
    title="",
}) => {
    let history = useHistory();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const styles = {
        tableCell: {
          padding: "5px 16px"
        },
        
        tableContainer: {
            maxHeight: 440,
            direction: "column",
            justifyContent: "center",
            alignItems: "center"
        },

        page: {
            margin: '2ch auto',
            width: '90%',
            overflow: 'hidden',
            justifyContent: "center",
            alignItems: "center",
            direction: "column",
        },

        button: {
            size: 'large',
            m: 1,
            width: '5ch',
            fontSize: '3ch',
        },
      };

    const handleSelect = (page, index) => {
        history.push({
            pathname: pathname,
            state: data[(page*10) + index]
        });
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <div className="table-container">
            <Paper sx={styles.page}>
                {title !== '' ?
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        {title}
                    </Typography>
                :
                    null
                }
                <TableContainer sx={styles.tableContainer}>
                    <Table
                        stickyHeader
                        aria-label="sticky table"
                    >
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={'ts_'+column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label !== 'Select' ? column.label : null}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {data
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, idx) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={'ts_tr_'+idx}>
                                    {columns.map((column) => {
                                        const value = row[column.id];
                                        const obj = (JSON.parse(sessionStorage.getItem('user')));
                                        return (
                                        <TableCell key={'ts_cb_'+column.id} align={column.align} sx={styles.tableCell}>
                                        {obj.role === 'ROLE_ADMIN' && column.id === 'select' ?
                                            <Button 
                                                sx={styles.button}
                                                // variant="outlined"
                                                onClick={()=>handleSelect(page, idx)}
                                            >
                                                <FontAwesomeIcon icon={faEdit} />
                                            </Button>
                                            : 
                                            column.id === 'active' ? value === true ? 'yes' : 'no' 
                                            : column.format && typeof value === 'number'
                                            ? column.format(value)
                                            : value}
                                        </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}

// ViewTimeSheets.propTypes = {
//     name: PropTypes.string
//   };

export default TableTemplate