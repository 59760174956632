import React from 'react';
import { Button, Box, Typography, Modal,
    TextField, FormControl, FormLabel,
    RadioGroup, FormControlLabel, Radio,
    InputLabel, Select, MenuItem } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import '../css/Modal.css';

const style = {
    box: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    },

    gradeFormCtrl: { width: '40%', marginTop: '1em' },

    grade: { width: '90%' },

    start: { width: '40%', marginTop: '1em' },
};

const ModalPlacement = ({
    open=false, 
    update=false,
    handleCancel=null,
    handleOk=null,
    handleModify=null,
    assignment=null,
    setAssignment=null,
}) => {

    const optGrades = [
        {label: 'None', value: 0},
        {label: '1st', value: 1},
        {label: '2nd', value: 2},
        {label: '3rd', value: 3},
        {label: '4th', value: 4},
        {label: '5th', value: 5},
        {label: '6th', value: 6},
        {label: '7th', value: 7},
        {label: '8th', value: 8},
        {label: '9th', value: 9},
        {label: '10th', value: 10},
    ]

    const handleSetSubject = (value) => {
        let clone = {...assignment};
        clone.subject = value;
        setAssignment(clone);
    }

    const handleSetGrade = (value) => {
        let clone = {...assignment};
        clone.grade = value;
        setAssignment(clone);
    }

    const handleSetRate = (value) => {
        let clone = {...assignment};
        clone.rate = value;
        setAssignment(clone);
    }

    const handleSetStart = (value) => {
        let clone = {...assignment};
        clone.start = value;
        setAssignment(clone);
    }

    const handleModalOK = () => {
        handleOk();
    }

    const handleSelectModify = () => {
        handleModify();
    }

    const handleModalCancel = () => {
        handleCancel();
    }

    return(
        <Modal
            open={open}
            onClose={handleCancel}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style.box}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {assignment.name}
                </Typography>

                <div>
                    <FormControl sx={{ m: 2 }}>
                        <FormLabel id="demo-radio-buttons-group-label">Subject</FormLabel>
                        <RadioGroup
                            sx={{
                                // boxShadow: 3,
                                // backgroundColor: "rgba(0,0,0,0.1)",
                                "& .MuiRadio-root": { color: "green" },
                                "& .MuiRadio-root.Mui-checked": { color: "orange" },
                            }}
                            aria-labelledby="demo-radio-buttons-group-label"
                            row
                            defaultValue="Math"
                            value={assignment.subject}
                            name="radio-buttons-group"
                            onChange={e=>handleSetSubject(e.target.value)}
                        >
                            <FormControlLabel value="Math" control={<Radio />} label="Math" />
                            <FormControlLabel value="Ela" control={<Radio />} label="ELA" />
                            <FormControlLabel value="Other" control={<Radio />} label="Other" />
                        </RadioGroup>
                    </FormControl>
                </div>

                <div>
                    <FormControl
                        variant="outlined"
                        margin={"dense"}
                        style={style.gradeFormCtrl}
                    >
                        <InputLabel id="test-select-label">Grade</InputLabel>
                        <Select
                            sx={style.grade}
                            labelId="test-select-label"
                            id="grade"
                            value={assignment.grade}
                            label="Grade"
                            onChange={e=>handleSetGrade(e.target.value)}
                        >
                            {optGrades.map((item, idx) => {
                                return(
                                    <MenuItem key={"ts_tutors_"+idx} value={item.value}>{item.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Start Date"
                            // views={['month','year']}
                            value={assignment.start}
                            onChange={newValue => { handleSetStart(newValue); }}
                            renderInput={params => <TextField {...params} sx={style.start}/>}
                            
                        />
                    </LocalizationProvider>
                </div>

                <div> 
                    <TextField
                        sx={{ m: 1, width: '30%' }}
                        // required
                        variant="filled"
                        id="rate"
                        label="Rate"
                        value={assignment.rate}
                        onChange={e => handleSetRate(e.target.value)}
                    />
                </div>

                {!update ?
                    <Button
                        sx={{ m: 1, width: '15ch' }}
                        variant="outlined"
                        onClick={handleModalOK}
                    >
                        OK
                    </Button>
                :
                <Button
                    sx={{ m: 1, width: '15ch' }}
                    variant="outlined"
                    onClick={handleSelectModify}
                >
                    Modify
                </Button>
                }                
                
                <Button
                    sx={{ m: 1, width: '15ch' }}
                    variant="outlined"
                    onClick={handleModalCancel}
                >
                    Cancel
                </Button>
            </Box>
        </Modal>
    )
}

export default ModalPlacement;