import React, {useState} from 'react';
import { Button, TextField, Box, 
    Select, MenuItem, Modal, FormControl, InputLabel,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import backendApi from '../../api/BackendApi.js';
import Message from '../Message.js';
import '../../css/Tutors.css';
import usStates from '../../usStates.js';

const CreateSchoolModal = ({
    open_modal=false,
    update=false,
    schoolData=null,
    handleOk=null,
    handleClose=null,
    setSchoolData=null,
}) => {
    const [openStatusModal, setOpenStatusModal] = useState(false);
    const [statusMsg, setStatusMsg] = useState('');
    const handleCloseSub = () => setOpenStatusModal(false);

    const desktop = useMediaQuery('(min-width:450px)');

    const style = {
        box: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            height: '70%',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        },

        button: {
            mt: 1,
            ml: 5,
            mr: 5, 
            width: '20%',
        },

        fieldName: {
            width: desktop ? '35%' : '80%',
            m: 1,
        },

        fieldEmail: {
            width: desktop ? '35%' : '80%',
            m: 1,
        },

        fieldAddress: {
            width: desktop ? '35%' : '80%',
            m: 1,
        },

        fieldCity: {
            width: desktop ? '25%' : '42%',
            m: 1,
        },

        fieldZip: {
            width: desktop ? '17%' : '19%',
            m: 1,
        },

        fieldPhone: {
            width: desktop ? '20%' : '57%',
            m: 1,
        },

        fieldContact: {
            width: desktop ? '35%' : '57%',
            m: 1,
        },
    
        selectContainer: {
            width: '5em',
            marginRight: '1em',
        },
    
        select: {
            color: 'black',
            width: '100%',
        },
    };

    const handleSubmit = () => {
        if((schoolData.name !== '') && (schoolData.address !== '') && (schoolData.city !== '')) {
            const data = {
                name: schoolData.name,
                email: schoolData.email,
                phone: schoolData.phone,
                address: schoolData.address,
                city: schoolData.city,
                state: schoolData.state,
                zipcode: schoolData.zipcode,
                contact: schoolData.contact,
            }

            backendApi.post("/school/", data)
                .then(result => {
                    if (result === true) {
                        handleOk();
                    } else {
                        setStatusMsg('Create Fail');
                        setOpenStatusModal(true);
                    }
                    // setOpen(true);
                })
                .catch(error => {
                    console.log(error.code);
                });
        } else {
            setStatusMsg("Missing information");
            setOpenStatusModal(true);
        }
    }

    const handleModify = () => {
        if((schoolData.name !== '') && (schoolData.address !== '') && (schoolData.city !== '')) {
            const data = {
                id: schoolData.id,
                name: schoolData.name,
                email: schoolData.email,
                phone: schoolData.phone,
                address: schoolData.address,
                city: schoolData.city,
                state: schoolData.state,
                zipcode: schoolData.zipcode,
                contact: schoolData.contact,
            }
            backendApi.update("/school/", data)
                .then(result => {
                    if (result === true) {
                        handleOk();
                    } else {
                        setStatusMsg('Create Fail');
                        setOpenStatusModal(true);
                    }
                    // setOpen(true);
                })
                .catch(error => {
                    console.log(error.code);
                });
        } else {
            setStatusMsg("Missing information");
            setOpenStatusModal(true);
        }
    }

    const handleSchoolData = (value, index) => {
        let clone = structuredClone(schoolData);
        
        if (index === 'phone') {
            if ((value.length === 3) && (schoolData.phone.length === 2)) {
                value = value + '-';
            }
            
            if ((value.length === 7) && (schoolData.phone.length === 6)) {
                value = value + '-';
            }
    
            if(value.length < 13) {
                clone[index] = value;
                setSchoolData(clone);
            }
        }
        else {
            clone[index] = value;
            setSchoolData(clone);
        }
    }

    return (
        <Modal
            open={open_modal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style.box}>
                {update?
                    <h3> Modify School </h3>
                    :
                    <h3> Create School </h3>
                }

                <Message
                    message={statusMsg}
                    open={openStatusModal}
                    btn1={'OK'}
                    handleClose={handleCloseSub}
                    handleBtn1={handleCloseSub}
                />

                <div className='data-input-section'>
                    <div>
                        <TextField
                            sx={style.fieldName}
                            required
                            id="name"
                            label="School Name"
                            variant="filled"
                            onChange={e => handleSchoolData(e.target.value, 'name')}
                            value={schoolData.name}
                        />

                        <TextField
                            sx={style.fieldAddress}
                            required
                            id="address"
                            label="Address"
                            variant="filled"
                            onChange={e => handleSchoolData(e.target.value, 'address')}
                            value={schoolData.address}
                        />
                    </div>

                    <div>
                        <TextField
                            sx={style.fieldCity}
                            required
                            id="city"
                            label="City"
                            variant="filled"
                            onChange={e => handleSchoolData(e.target.value, 'city')}
                            value={schoolData.city}
                        />

                        <FormControl
                            variant="outlined"
                            margin={"dense"}
                            style={style.selectContainer}
                        >
                            <InputLabel id="test-select-label">State</InputLabel>
                            <Select
                                sx={style.select}
                                labelId="test-select-label"
                                id="state"
                                value={schoolData.state}
                                label="State"
                                onChange={e => handleSchoolData(e.target.value, 'state')}
                            >
                                {usStates.map((item, idx) => {
                                    return(
                                        <MenuItem key={"optSchoolState"+idx} value={item.value}>{item.label}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>

                        <TextField
                            sx={style.fieldZip}
                            required
                            id="zipcode"
                            label="Zip"
                            variant="filled"
                            onChange={e => handleSchoolData(e.target.value, 'zipcode')}
                            value={schoolData.zipcode}
                        />

                        <TextField
                            sx={style.fieldPhone}
                            required
                            id="phone"
                            label="Phone"
                            variant="filled"
                            onChange={e => handleSchoolData(e.target.value, 'phone')}
                            value={schoolData.phone}
                        />
                    </div>

                    <div>
                        <TextField
                            sx={style.fieldContact}
                            required
                            id="contactName"
                            label="Contact Name"
                            variant="filled"
                            onChange={e => handleSchoolData(e.target.value, 'contact')}
                            value={schoolData.contact}
                        />

                        <TextField
                            sx={style.fieldEmail}
                            required
                            id="email"
                            label="Email"
                            variant="filled"
                            onChange={e => handleSchoolData(e.target.value, 'email')}
                            value={schoolData.email}
                        />
                    </div>

                    <div>
                        {!update ?
                            <div>
                                <Button sx={style.button} variant="outlined" onClick={handleSubmit}>Submit</Button>
                                <Button sx={style.button} variant="outlined" onClick={handleClose}>Cancel</Button>
                            </div>
                        :
                            <div>
                                <Button sx={style.button} variant="outlined" onClick={handleModify}>Modify</Button>                
                                <Button sx={style.button} variant="outlined" onClick={handleClose}>Cancel</Button>
                            </div>
                        }
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default CreateSchoolModal;