import React, {useState} from 'react';
import { 
    Paper, Table, TableBody, TableCell,
    TableContainer, TableHead, TablePagination,
    TableRow, Button,
} from "@mui/material";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../../css/Tutors.css';

const TableTimesheet = ({
    data=null,
    columns=null,
    handleSelect=null,
    handleDeleteTimeEntry=null,
}) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const styles = {
        tableCell: {
          padding: "5px 16px"
        },
        
        tableContainer: {
            maxHeight: 440,
            direction: "column",
            justifyContent: "center",
            alignItems: "center"
        },

        page: {
            margin: '2ch auto',
            width: '90%',
            overflow: 'hidden',
            justifyContent: "center",
            alignItems: "center",
            direction: "column",
        },

        button: {
            size: 'large',
            m: 1,
            width: '5ch',
            fontSize: '3ch',
        },
      };

    const handleEdit = (page, index) => {
        handleSelect(data[(page*10) + index]);
    }

    const handleDelete = (page, index) => {
        handleDeleteTimeEntry(data[(page*10) + index]);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <div className="table-container">
            <Paper sx={styles.page}>
                <TableContainer sx={styles.tableContainer}>
                    <Table
                        stickyHeader
                        aria-label="sticky table"
                    >
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={'ts_'+column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {data
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, idx) => {
                            return (
                                <TableRow
                                    hover role="checkbox"
                                    tabIndex={-1}
                                    key={'ts_tr_'+idx}
                                >
                                    {columns.map((column) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell
                                                key={'ts_cb_'+column.id}
                                                align={column.align}
                                                sx={styles.tableCell}
                                            >
                                            { column.id === 'select' ?
                                                <Button 
                                                    sx={styles.button}
                                                    onClick={()=>handleEdit(page, idx)}
                                                >
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </Button>
                                            :
                                                column.id === 'delete' ?
                                                    <Button 
                                                        sx={styles.button}
                                                        onClick={()=>handleDelete(page, idx)}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Button>
                                                :
                                                    column.id === 'active' ?
                                                        value === true ?
                                                            'yes' : 'no' 
                                                    :
                                                        column.format && typeof value === 'number' ?
                                                            column.format(value) : value
                                            }
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}

// ViewTimeSheets.propTypes = {
//     name: PropTypes.string
//   };

export default TableTimesheet