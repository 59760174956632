import React, {useState} from 'react';
import {Box, Button, TextField, Select, MenuItem,
    Modal, FormControlLabel, Checkbox, FormControl, InputLabel,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import backendApi from '../../api/BackendApi.js';
import Message from '../Message.js';
import '../../css/Tutors.css';
import usStates from '../../usStates.js';

const CreateUserModal = ({
    open_modal=false,
    update=false,
    employeeData=null,
    handleOk=null,
    handleClose=null,
    setEmployeeData=null,
}) => {
    const [openStatusModal, setOpenStatusModal] = useState(false);
    const [statusMsg, setStatusMsg] = useState('');
    const handleCloseSub = () => setOpenStatusModal(false);
    const desktop = useMediaQuery('(min-width:450px)');

    const style = {
        box: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            height: '80%',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        },

        button: {
            mt: 1,
            ml: 5,
            mr: 5, 
            width: '20%',
        },
    
        selectContainer: {
            width: '5em',
            marginRight: '1em',
        },
    
        select: {
            color: 'black',
            width: '100%',
        },

        fieldName: {
            width: desktop ? '35%' : '80%',
            m: 1,
        },

        fieldEmail: {
            width: desktop ? '35%' : '80%',
            m: 1,
        },

        fieldAddress: {
            width: desktop ? '35%' : '80%',
            m: 1,
        },

        fieldCity: {
            width: desktop ? '25%' : '42%',
            m: 1,
        },

        fieldZip: {
            width: desktop ? '17%' : '19%',
            m: 1,
        },

        fieldPhone: {
            width: desktop ? '35%' : '57%',
            m: 1,
        },

        fieldRate: {
            width: desktop ? '15%' : '20%',
            m: 1,
        },
    };

    const handleSubmit = () => {
        if((employeeData.name !== '') && (employeeData.email !== ''))
        {
            let clone = structuredClone(employeeData);
            clone.active = clone.active === false ? 0 : clone.active;
            clone.bilingual = clone.bilingual === false ? 0 : clone.bilingual;
            clone.email = clone.email.toLocaleLowerCase();
            
            backendApi.post('/user/', clone)
                .then(result => {
                    if (result) {
                        handleOk();
                    } else {
                        setStatusMsg('Create Fail');
                        setOpenStatusModal(true);
                    }
                })
                .catch(error => {
                    console.log(error.code);
                });
            // handleClose();
        } else {
            setStatusMsg("Save Failed");
            setOpenStatusModal(true);
        }
    }

    const handleModify = () => {
        if((employeeData.name !== '') && (employeeData.email !== ''))
        {
            employeeData.active = employeeData.active === false ? 0 : employeeData.active;
            employeeData.bilingual = employeeData.bilingual === false ? 0 : employeeData.bilingual;
            employeeData.email = employeeData.email.toLocaleLowerCase();
            
            backendApi.update("/profile/", employeeData)
                .then(result => {
                    if (result) {
                        handleOk();
                    } else {
                        setStatusMsg('Create Fail');
                        setOpenStatusModal(true);
                    }
                    // setOpen(true);
                })
                .catch(error => {
                    console.log(error.code);
                });
        } else {
            setStatusMsg("Missing information");
            setOpenStatusModal(true);
        }
    }

    const handleEmployeeData = (value, index) => {
        let clone = structuredClone(employeeData);
        
        if (index === 'phone') {
            if ((value.length === 3) && (employeeData.phone.length === 2)) {
                value = value + '-';
            }
            
            if ((value.length === 7) && (employeeData.phone.length === 6)) {
                value = value + '-';
            }
    
            if(value.length < 13) {
                clone[index] = value;
                setEmployeeData(clone);
            }
        }
        else {
            clone[index] = value;
            setEmployeeData(clone);
        }
    }

    return (
        <Modal
            open={open_modal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style.box}>

                {update?
                    <h5> Modify Employee </h5>
                    :
                    <h5> Create Employee </h5>
                }

                <Message
                    message={statusMsg}
                    open={openStatusModal}
                    btn1={'OK'}
                    handleClose={handleCloseSub}
                    handleBtn1={handleCloseSub}
                />

                <div className='data-input-section'>
                    <div>
                        <TextField
                            sx={style.fieldName}
                            required
                            id="name"
                            label="Name"
                            variant="filled"
                            onChange={e => handleEmployeeData(e.target.value, 'name')}
                            value={employeeData.name}
                        />
                    {/* </div>

                    <div> */}
                        <TextField
                            sx={style.fieldEmail}
                            required
                            id="email"
                            label="Email"
                            variant="filled"
                            onChange={e => handleEmployeeData(e.target.value, 'email')}
                            value={employeeData.email}
                        />
                    </div>

                    <div>
                        <TextField
                            sx={style.fieldAddress}
                            required
                            id="address"
                            label="Address"
                            variant="filled"
                            onChange={e => handleEmployeeData(e.target.value, 'address')}
                            value={employeeData.address}
                        />
                    {/* </div>

                    <div> */}
                        <TextField
                            sx={style.fieldCity}
                            required
                            id="city"
                            label="City"
                            variant="filled"
                            onChange={e => handleEmployeeData(e.target.value, 'city')}
                            value={employeeData.city}
                        />

                        <FormControl
                            variant="outlined"
                            margin={"dense"}
                            style={style.selectContainer}
                        >
                            <InputLabel id="test-select-label">State</InputLabel>
                            <Select
                                sx={style.select}
                                labelId="test-select-label"
                                id="state"
                                value={employeeData.state}
                                label="State"
                                onChange={e=>handleEmployeeData(e.target.value, 'state')}
                            >
                                {usStates.map((item, idx) => {
                                    return(
                                        <MenuItem key={"optSUsertate"+idx} value={item.value}>{item.label}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </div>

                    <div>
                        <TextField
                            sx={style.fieldZip}
                            required
                            id="zipcode"
                            label="Zip"
                            variant="filled"
                            onChange={e => handleEmployeeData(e.target.value, 'zipcode')}
                            value={employeeData.zipcode}
                        />

                        <TextField
                            sx={style.fieldPhone}
                            required
                            id="phone"
                            label="Phone"
                            variant="filled"
                            onChange={e => handleEmployeeData(e.target.value, 'phone')}
                            value={employeeData.phone}
                        />
                    </div>
                    <div> 
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={employeeData.active===1?true:employeeData.active===0?false:employeeData.active}
                                    onChange={e=>handleEmployeeData(e.target.checked, 'active')}
                                    size="large"
                                />
                            }
                            label="Active"
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={employeeData.bilingual===1?true:employeeData.bilingual===0?false:employeeData.bilingual}
                                    onChange={e=>handleEmployeeData(e.target.checked, 'bilingual')}
                                    size="large"
                                />
                            }
                            label="Bilingual"
                        />
                    </div>

                    <div>
                        {!update ?
                            <div>
                                <Button sx={style.button} variant="outlined" onClick={handleSubmit}>Submit</Button>
                                <Button sx={style.button} variant="outlined" onClick={handleClose}>Cancel</Button>
                            </div>
                        :
                            <div>
                                <Button sx={style.button} variant="outlined" onClick={handleModify}>Modify</Button>
                                <Button sx={style.button} variant="outlined" onClick={handleClose}>Cancel</Button>
                            </div>
                        }
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default CreateUserModal;