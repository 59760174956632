import React, {useState, useEffect} from 'react';
import TableTemplate from './TableTemplate.js';
import backendApi from '../../api/BackendApi.js';
import '../../css/Tutors.css';

const Summary = ({user=null}) => {
    const [timesheet, setTimeSheet] = useState([]);
    const [payroll, setPayroll] = useState([]);
    
    useEffect(() => {
        const params = {id: user.id, rows: 5};
        backendApi.get("/timesheet/latest", params)
            .then(data => {
                setTimeSheet(data);
            })
            .catch((error) => {
                console.log(error.code)
                if (error.code === 'permission-denied') {
                    // setMsg("Insufficient permission");
                }
            });

        backendApi.get("/timesheet/payroll_multiple", {id: user.id})
            .then(data => {
                setPayroll(data);
            })
            .catch((error) => {
                console.log(error.code)
                if (error.code === 'permission-denied') {
                    // setMsg("Insufficient permission");
                }
            });
    }, [user]);

    const columns = [
        { id: 'date', label: 'Date', minWidth: 100 },
        { id: 'time_in', label: 'In', minWidth: 50 },
        { id: 'time_out', label: 'Out', minWidth: 50 },
        { id: 'hours_worked', label: 'Hours', minWidth: 40 },
        { id: 'school', label: 'School', minWidth: 40 },
        { id: 'description', label: 'Description', minWidth: 200 },
    ];

    const columnsPayroll = [
        { id: 'date', label: 'Month', minWidth: 100 },
        { id: 'hours', label: 'Hours', minWidth: 150 },
        { id: 'rate', label: 'Rate', minWidth: 50 },
        { id: 'total', label: 'Total', minWidth: 50 },
    ];

    return (
        <div className="body-container">
            <div> Summary for {user !== null ? user.name : null } </div>
            {user !== null ?
                <div className='tutorInfo'>
                    <div className='tutor-side1'>
                        <div className='tutorItem'> Email: {user.email} </div>
                        <div className='tutorItem'> Address: {user.address} </div>
                        <div className='tutorItem'> City: {user.city} </div>
                        <div className='tutorItem'> State: {user.state} </div>
                    </div>
                    <div className='tutor-side2'>
                        <div className='tutorItem'> Pay Rate: ${user.assignments[0].rate}/hr </div>
                        {user.assignments.map((item, idx) => {
                            return(
                                <div key={'sch'+idx} className='tutorItem'> School: {item.school} </div>
                            )
                        })}
                    </div>
                </div>
                : null
            } 
            <TableTemplate data={timesheet} columns={columns} title="Last 5 Timesheet Entries" />
            <TableTemplate data={payroll} columns={columnsPayroll} title="Last 3 Paychecks" />
        </div>
    );
}

export default Summary;