import React, {useState, useEffect} from 'react';
import { Button, } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableSchool from './TableSchool.js';
import CreateSchoolModal from '../create//CreateSchoolModal.js'
import Message from '../Message.js';
import backendApi from '../../api/BackendApi.js';
import '../../css/Tutors.css';

const ViewSchools = () => {

    const desktop = useMediaQuery('(min-width:450px)');

    const style = {
        button: {
            mt: 1,
            ml: 5,
            mr: 5,
            width: '30ch',
            height: '5ch',
        },
    };

    const [schools, setSchools] = useState([]);
    const [open, setOpen] = useState(false);
    const [update, setUpdate] = useState(false);
    const [statusMsg, setStatusMsg] = useState('');
    const [openDelete, setOpenDelete] = useState(false);
    const [schoolData, setSchoolData] = useState({
        id: 0,
        email: '',
        name: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        zipcode: '',
        contact: '',
        active: 0,
    });

    const columns = [
        { id: 'name', label: 'Name', minWidth: 150 },
        { id: 'email', label: 'Email', minWidth: 150 },
        { id: 'phone', label: 'Phone', minWidth: 75 },
        { id: 'address', label: 'Address', minWidth: 50 },
        // { id: 'rate', label: 'Rate', minWidth: 50, align: 'right' },
        { id: 'edit', label: 'Edit', minWidth: 100, align: 'center' },
        { id: 'view', label: 'View', minWidth: 50, align: 'center' },
        { id: 'report', label: 'Report', minWidth: 100, align: 'center' },
        { id: 'delete', label: 'Delete', minWidth: 50, align: 'center' },
    ];
    
    useEffect(() => {
        backendApi.get('/school/')
            .then(data => {
                setSchools(data)
            })
            .catch((error) => {
                console.log(error.code)
                if (error.code === 'permission-denied') {
                    // setMsg("Insufficient permission");
                }
            });
    }, [])
    
    const handleTableUpdate = () => {
        setUpdate(false);
        setOpen(false);
        setSchoolData({
            id: 0,
            email: '',
            name: '',
            phone: '',
            address: '',
            city: '',
            state: '',
            zipcode: '',
            contact: '',
            active: 0,
        });
        backendApi.get("/school/")
            .then((data) => {
                if (data.message) {
                    setSchools([]);
                } else {
                    setSchools(data);
                }
            })
            .catch((error) => {
                console.log(error)
                if (error.code === 'permission-denied') {
                    // setMsg("Insufficient permission");
                }
            });
    };

    const handleSelect = (param) => {
        setSchoolData(param);
        setUpdate(true)
        setOpen(true);
    }

    // Called when create/update modal closes
    const handleClose = () => {
        setUpdate(false);
        setOpen(false);
        setSchoolData({
            id: 0,
            email: '',
            name: '',
            phone: '',
            address: '',
            city: '',
            state: '',
            zipcode: '',
            contact: '',
            active: 0,
        });
    }

    const handleDeleteSchool = (id) => {
        setOpenDelete(false);
        const data={id: schoolData.id};
        backendApi.delete("/school/", data)
            .then(result => {
                if (result) {
                    backendApi.get('/school/')
                        .then(data => {
                            setSchools(data)
                        })
                        .catch((error) => {
                            console.log(error.code)
                            if (error.code === 'permission-denied') {
                                // setMsg("Insufficient permission");
                            }
                        });
                } else {
                    // setStatusMsg('Create Fail');
                    // setOpen(true);
                    console.log("error")
                }
            })
            .catch(error => {
                console.log(error.code);
            });
    }

    const handleCloseDeleteModal = () => setOpenDelete(false);

    const handleOpenDeleteModal = (param) => {
        setSchoolData(param);
        setStatusMsg(<div><p>Are you sure you want to delete</p><p>{param.name}?</p></div>)
        setOpenDelete(true)
    };

    // const handleEmployee = (param) => {
    //     setSchoolData(param);
    //     setUpdate(true)
    //     setOpen(true);
    // }

    return (
        <div className="body-container">

            <Message
                message={statusMsg}
                open={openDelete}
                btn1={'OK'}
                btn2={'Cancel'}
                handleClose={handleCloseDeleteModal}
                handleBtn1={handleDeleteSchool}
                handleBtn2={handleCloseDeleteModal}
            />

            <CreateSchoolModal
                open_modal={open}
                update={update}
                schoolData={schoolData}
                handleOk={handleTableUpdate}
                handleClose={handleClose}
                setSchoolData={setSchoolData}
            />

            <div className='title-bar'>
                Schools

                {desktop ?
                    <Button
                        sx={style.button}
                        variant="outlined"
                        onClick={() => setOpen(true)}
                    >
                        <div className='btn-add-icon'>
                            <FontAwesomeIcon icon={faPlusCircle} />
                        </div>
                        <div> Add School</div>
                    </Button>
                :
                    null
                }
            </div>

            <TableSchool
                data={schools}
                columns={columns}
                pathname={'create-school'}
                handleSelect={handleSelect}
                handleDeleteSchool={handleOpenDeleteModal}
            />
        </div>
    );
}

export default ViewSchools;