import React, {useState, useEffect} from 'react';
import { useLocation } from "react-router-dom";
import { TextField } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import TableTemplate from './TableTemplate.js';
import backendApi from '../../api/BackendApi.js';
import moment from 'moment'
import '../../css/Tutors.css';

const ViewEmployee = () => {
    const location = useLocation();
    const [employee, setEmployee] = useState({});
    const [timesheet, setTimeSheet] = useState([]);
    const [payments, setPayments] = useState([]);
    const [startDate, setStartDate] = useState();

    const desktop = useMediaQuery('(min-width:450px)');

    const styles = {
        dates: {
            width: desktop ? '15%' : '33%',
            mt: '1ch',
        },
      };
    
    useEffect(() => {
        let emp_id = location.state;

        //GET PROFILE/SINGLE
        backendApi.get("/profile/single", {id: emp_id})
            .then(result => {
                setEmployee(result);
            })
            .catch((error) => {
                console.log(error.code)
                if (error.code === 'permission-denied') {
                    // setMsg("Insufficient permission");
                }
            });

        // dateFns.startOfMonth(new Date())
        let startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
        let endOfMonth = moment().endOf('month').format("YYYY-MM-DD");

        //GET TIMESHEET/EMPLOYEE-RANGE
        let params = {
            id: emp_id,
            start: startOfMonth,
            end: endOfMonth,
        };

        backendApi.get("/timesheet/employee-range", params)
            .then(result => {
                setTimeSheet(result.time_sheet);
            })
            .catch((error) => {
                console.log(error.code)
                if (error.code === 'permission-denied') {
                    // setMsg("Insufficient permission");
                }
            });

        //GET PAYMENT/SINGLE
        backendApi.get("/payment/single", params)
            .then(result => {
                setPayments(result);
            })
            .catch((error) => {
                console.log(error.code)
                if (error.code === 'permission-denied') {
                    // setMsg("Insufficient permission");
                }
            });
    }, [location.state]);

    const columns = [
        { id: 'date', label: 'Date', minWidth: 100 },
        { id: 'time_in', label: 'In', minWidth: 50 },
        { id: 'time_out', label: 'Out', minWidth: 50 },
        { id: 'hours_worked', label: 'Hours', minWidth: 50 },
        { id: 'school', label: 'School', minWidth: 50 },
        { id: 'description', label: 'Description', minWidth: 200 },
        // { id: 'select', label: 'Select', minWidth: 50 },
    ];

    const columnsPayment = [
        { id: 'month', label: 'Month', minWidth: 100 },
        { id: 'amount', label: 'Amount', minWidth: 50 },
        { id: 'hours', label: 'Hours', minWidth: 50 },
        { id: 'rate', label: 'Rate', minWidth: 50 },
        { id: 'description', label: 'Description', minWidth: 200 },
    ];

    const handleSelectStart = (e) => {
        setStartDate(e);
        
        //GET TIMESHEET/EMPLOYEE-RANGE
        let params = {
            id: employee.id,
            start: moment(e).startOf('month').format('YYYY-MM-DD'),
            end: moment(e).endOf('month').format("YYYY-MM-DD"),
        };

        backendApi.get("/timesheet/employee-range", params)
            .then(result => {
                setTimeSheet(result.time_sheet);
            })
            .catch((error) => {
                console.log(error.code)
                if (error.code === 'permission-denied') {
                    // setMsg("Insufficient permission");
                }
            });
    }

    return (
        <div className="body-container">
            <div> {employee.name} </div>
            {Object.keys(employee).length !== 0 ? 
                <div className='tutorInfo'>
                    <div className='tutor-side1'>
                        <div className='tutorItem'> Email: {employee.email} </div>
                        <div className='tutorItem'> Address: {employee.address} </div>
                        <div className='tutorItem'> City: {employee.city} </div>
                        <div className='tutorItem'> State: {employee.state} </div>
                    </div>
                    <div className='tutor-side2'>
                        <div className='tutorItem'> Pay Rate: ${employee.contracts[0].rate}/hr </div>
                        {employee.contracts.map((item, idx) => {
                            return(
                                <div key={'combo'+idx} className='tutorItem'> Contract: {item.contract_num} </div>
                            )
                        })}
                    </div>
                </div>
            : null}

            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    label="Month"
                    views={['month','year']}
                    value={startDate}
                    onChange={e => { handleSelectStart(e) }}
                    renderInput={params => <TextField {...params} sx={styles.dates}/>}
                />
            </LocalizationProvider>

            <TableTemplate data={timesheet} columns={columns} title="Timesheet Entries" />
            <TableTemplate data={payments} columns={columnsPayment} title="Payments" />
        </div>
    );
}

export default ViewEmployee;