const usStates = [
    { label: 'AK', value: 'AK'},  //'Alaska'},
    { label: 'AL', value: 'AL'},  //'Alabama'},
    { label: 'AR', value: 'AR'},  //'Arkansas'},
    { label: 'AZ', value: 'AZ'},  //'Arizona'},
    { label: 'CA', value: 'CA'},  //'California'},
    { label: 'CO', value: 'CO'},  //'Colorado'},
    { label: 'CT', value: 'CT'},  //'Connecticut'},
    { label: 'DC', value: 'DC'},  //'DistrictofColumbia'},
    { label: 'DE', value: 'DE'},  //'Delaware'},
    { label: 'FL', value: 'FL'},  //'Florida'},
    { label: 'GA', value: 'GA'},  //'Georgia'},
    { label: 'HI', value: 'HI'},  //'Hawaii'},
    { label: 'IA', value: 'IA'},  //'Iowa'},
    { label: 'ID', value: 'ID'},  //'Idaho'},
    { label: 'IL', value: 'IL'},  //'Illinois'},
    { label: 'IN', value: 'IN'},  //'Indiana'},
    { label: 'KS', value: 'KS'},  //'Kansas'},
    { label: 'KY', value: 'KY'},  //'Kentucky'},
    { label: 'LA', value: 'LA'},  //'Louisiana'},
    { label: 'MA', value: 'MA'},  //'Massachusetts'},
    { label: 'MD', value: 'MD'},  //'Maryland'},
    { label: 'ME', value: 'ME'},  //'Maine'},
    { label: 'MI', value: 'MI'},  //'Michigan'},
    { label: 'MN', value: 'MN'},  //'Minnesota'},
    { label: 'MO', value: 'MO'},  //'Missouri'},
    { label: 'MS', value: 'MS'},  //'Mississippi'},
    { label: 'MT', value: 'MT'},  //'Montana'},
    { label: 'NC', value: 'NC'},  //'NorthCarolina'},
    { label: 'ND', value: 'ND'},  //'NorthDakota'},
    { label: 'NE', value: 'NE'},  //'Nebraska'},
    { label: 'NH', value: 'NH'},  //'NewHampshire'},
    { label: 'NJ', value: 'NJ'},  //'NewJersey'},
    { label: 'NM', value: 'NM'},  //'NewMexico'},
    { label: 'NV', value: 'NV'},  //'Nevada'},
    { label: 'NY', value: 'NY'},  //'NewYork'},
    { label: 'OH', value: 'OH'},  //'Ohio'},
    { label: 'OK', value: 'OK'},  //'Oklahoma'},
    { label: 'OR', value: 'OR'},  //'Oregon'},
    { label: 'PA', value: 'PA'},  //'Pennsylvania'},
    { label: 'RI', value: 'RI'},  //'RhodeIsland'},
    { label: 'SC', value: 'SC'},  //'SouthCarolina'},
    { label: 'SD', value: 'SD'},  //'SouthDakota'},
    { label: 'TN', value: 'TN'},  //'Tennessee'},
    { label: 'TX', value: 'TX'},  //'Texas'},
    { label: 'UT', value: 'UT'},  //'Utah'},
    { label: 'VA', value: 'VA'},  //'Virginia'},
    { label: 'VT', value: 'VT'},  //'Vermont'},
    { label: 'WA', value: 'WA'},  //'Washington'},
    { label: 'WI', value: 'WI'},  //'Wisconsin'},
    { label: 'WV', value: 'WV'},  //'WestVirginia'},
    { label: 'WY', value: 'WY'},  //'Wyoming'}
    ];

    export default usStates;