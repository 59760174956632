import React, {useEffect, useState} from 'react';
import { Button, TextField, Box, Modal,
    FormControl, InputLabel, Select, MenuItem,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment' // 292.3K (gzipped: 71.6K)
import Message from '../Message.js';
import backendApi from '../../api/BackendApi.js';
import '../../css/Tutors.css';
import '../../css/Tutors-Mobile.css';

const CreateContractModal = ({
    open_modal=false,
    update=false,
    contractData=null,
    handleOk=null,
    handleClose=null,
    setContractData=null,
}) => {
    const [openStatusModal, setOpenStatusModal] = useState(false);
    const [statusMsg, setStatusMsg] = useState('');
    const [optSchools, setOptSchools] = useState([]);
    const handleCloseSub = () => setOpenStatusModal(false);

    const desktop = useMediaQuery('(min-width:450px)');

    const style = {
        box: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            height: '80%',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        },

        date: { m: 1, width: '15ch'},

        purchaseOrder: {
            width: desktop ? '30%' : '82%',
            marginRight: '1em',
            marginTop: '1em',
            marginBottom: '1em',
        },

        contractNumber: {
            width: desktop ? '30%' : '82%',
            marginTop: '1em',
            marginBottom: '1em',
        },

        description: {
            width: '50%',
            // marginTop: '1em',
            marginBottom: '1em',
        },

        field: {
            width: desktop ? '41%' : '82%',
            m: 1,
        },

        halfField: {
            width: desktop ? '20%' : '39%',
            m: 1,
        },

        button: {
            mt: 1,
            ml: 5,
            mr: 5, 
            width: desktop ? '20%' : '25%',
        },
    
        school: {
            width: desktop ? '50%' : '80%',
            margin: 'auto',
        },

        select: {
            color: 'black',
            width: '100%',
            mb: '2ch',
        },
    };
    
    useEffect(() => {
        const tempSchools = [''];
        backendApi.get("/school/")
            .then(data => {
                data.map((item, idx) => (
                    tempSchools.push({label: item.name, value: item.id})
                ));
                setOptSchools(tempSchools);
            })
            .catch((error) => {
                console.log(error.code)
                if (error.code === 'permission-denied') {
                }
            });
    }, []);

    const handleSubmit = () => {
        if((contractData.school !== '') && 
            (contractData.start !== '') && 
            (contractData.end !== '') && 
            (contractData.purchase_order !== ''))
        {
            let clone = structuredClone(contractData);
            clone.start_date = moment(clone.start_date).format("YYYY-MM-DD");
            clone.end_date = moment(clone.end_date).format("YYYY-MM-DD");
            clone.school = parseInt(clone.school);
            clone.amount = clone.amount === '' ? 0 : parseFloat(clone.amount);
            delete clone.name;

            backendApi.post("/contract/", clone)
                .then(result => {
                    if (result === true) {
                        handleOk();
                    } else {
                        setStatusMsg('Create contract failed.')
                        setOpenStatusModal(true);
                    }
                })
                .catch(error => {
                    console.log(error.code);
                });
        } else {
            setStatusMsg("Missing information");
            setOpenStatusModal(true);
        }
    }

    const handleModify = () => {
        if((contractData.school !== '') && 
            (contractData.start_date !== '') && 
            (contractData.end_date !== '') && 
            (contractData.purchase_order !== ''))
        {
            let clone = structuredClone(contractData);
            clone.start_date = moment(clone.start_date).format("YYYY-MM-DD");
            clone.end_date = moment(clone.end_date).format("YYYY-MM-DD");
            clone.school = parseInt(clone.school);
            clone.amount = parseFloat(clone.amount);
            delete clone.name;

            backendApi.update("/contract/", clone)
                .then(result => {
                    if (result === true) {
                        handleOk();
                    } else {
                        setStatusMsg('Create Fail');
                        setOpenStatusModal(true);
                    }
                })
                .catch(error => {
                    console.log(error.code);
                });
        } else {
            setStatusMsg("Missing information");
            setOpenStatusModal(true);
        }
    }

    const handleContractData = (value, index) => {
        let clone = structuredClone(contractData);
        clone[index] = value;
        setContractData(clone);
    }

    const handleSelectSchool = (e) => {
        let clone = structuredClone(contractData);
        clone.school = e;
        let obj = optSchools.find(o => o.value === e);
        let temp = clone.contract_num.split('-');
        temp[0] = obj.label.replace(' ','').substring(0,4);
        clone.contract_num = temp.join('-');        
        setContractData(clone);
    }

    const handleStartDate = (e) => {
        let clone = structuredClone(contractData);
        clone.start_date = e;
        let temp = clone.contract_num.split('-');
        temp[1] = moment(e).format("YYYYMMDD");
        clone.contract_num = temp.join('-');
        setContractData(clone);
    }

    const handleEndtDate = (e) => {
        let clone = structuredClone(contractData);
        clone.end_date = e;
        let temp = clone.contract_num.split('-');
        temp[2] = moment(e).format("YYYYMMDD");
        clone.contract_num = temp.join('-');        
        setContractData(clone);
    }

    return (
        <Modal
            open={open_modal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style.box}>

                {update?
                    <h5> Modify Contract </h5>
                    :
                    <h5> Create Contract </h5>
                }

                <Message
                    message={statusMsg}
                    open={openStatusModal}
                    btn1={'OK'}
                    handleClose={handleCloseSub}
                    handleBtn1={handleCloseSub}
                />

                {update ?
                    <h4> {contractData.name} </h4>
                    :
                    <FormControl
                        variant="outlined"
                        margin={"dense"}
                        style={style.school}
                    >
                        <InputLabel id="test-select-label">School</InputLabel>
                        <Select
                            sx={style.select}
                            labelId="test-select-label"
                            id="school"
                            value={contractData.school}
                            label="School"
                            onChange={e=>handleSelectSchool(e.target.value)}
                        >
                            {optSchools.map((item, idx) => {
                                return(
                                    <MenuItem key={"optSchools"+idx} value={item.value}>{item.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                }

                <div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            sx={style.date}
                            label="Start Date"
                            minDate={new Date('2022-01-01')}
                            value={contractData.start_date}
                            onChange={e => handleStartDate(e)}
                            renderInput={params => <TextField {...params}
                                sx={style.date}/>}
                        />

                        <DatePicker
                            label="End Date"
                            minDate={new Date('2022-01-01')}
                            value={contractData.end_date}
                            onChange={e => handleEndtDate(e)}
                            renderInput={params => <TextField {...params}
                                sx={style.date}/>}
                        />
                    </LocalizationProvider>

                    <TextField
                        sx={style.halfField}
                        required
                        id="amount"
                        label="Amount"
                        variant="filled"
                        onChange={e => handleContractData(e.target.value, 'amount')}
                        value={contractData.amount}
                    />
                </div>

                <div>
                    <TextField
                        sx={style.purchaseOrder}
                        required
                        id="purchase_order"
                        label="Purchase Order"
                        variant="filled"
                        onChange={e => handleContractData(e.target.value, 'purchase_order')}
                        value={contractData.purchase_order}
                    />

                    <TextField
                        sx={style.contractNumber}
                        required
                        id="contract-number"
                        label="Contract Number"
                        variant="filled"
                        value={contractData.contract_num}
                    />
                </div>

                <div>
                </div>

                <div>
                    <TextField
                        sx={style.description}
                        required
                        id="description"
                        label="Description"
                        variant="filled"
                        onChange={e => handleContractData(e.target.value, 'description')}
                        value={contractData.description}
                    />
                </div>

                <div>
                    {!update ?
                        <div>
                            <Button sx={style.button} variant="outlined" onClick={handleSubmit}>Submit</Button>                
                            <Button sx={style.button} variant="outlined" onClick={handleClose}>Cancel</Button>
                        </div>
                    :
                        <div>
                            <Button sx={style.button} variant="outlined" onClick={handleModify}>Modify</Button>                
                            <Button sx={style.button} variant="outlined" onClick={handleClose}>Cancel</Button>
                        </div>
                    }
                </div>
            </Box>
        </Modal>
    )
}

export default CreateContractModal;