import axios from 'axios';

const directory = 'https://www.emceducators.com/api/v16';
// const directory = 'http://localhost:8080';

const backendApi = {

    get(url, data=null) {
        let obj = JSON.parse(sessionStorage.getItem('user'));
        const token = obj !== null ? obj.token : null;
        return axios.get(
            directory + url,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token,
                },
                params: data
            },
          )
         .then(response => {
            return response.data;
         })
        .catch(error => {
            console.log(error.response.status);
            return null;
        });
    },

    post(url, data=null) {
        let obj = JSON.parse(sessionStorage.getItem('user'));
        const token = obj !== null ? obj.token : null;
        return axios.post(
            directory + url,
            JSON.stringify(data),
            {
                headers: {
                  // 'application/json' is the modern content-type for JSON, but some
                  // older servers may use 'text/json'.
                  // See: http://bit.ly/text-json
                  Accept: 'application/json',
                  Authorization: 'Bearer ' + token,
                }
            }
          )
            .then(response => {
                return response.data;
            }).catch(function (error) {
                console.error(error);
                return null;
            });
    }, 
    
    update(url, data=null) {
        let obj = JSON.parse(sessionStorage.getItem('user'));
        const token = obj !== null ? obj.token : null;

        return axios.patch(
            directory + url,
            JSON.stringify(data),
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            }
          )
         .then(response => {
            return response.data;
         })
        .catch(error => {
            console.log(error.response.status);
            return null;
        });
    },

    delete(url, data=null) {
        const obj = (JSON.parse(sessionStorage.getItem('user')));
        const token = obj !== null ? obj.token : null;

        return axios.delete(
            directory + url,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify(data),
            },
        )
         .then(response => {
            return response.data;
         })
        .catch(error => {
            return error.response.data  
        });
    },

    download(url, data=null) {
        let obj = JSON.parse(sessionStorage.getItem('user'));
        const token = obj !== null ? obj.token : null;
        axios({
            url: directory + url,
            method: 'GET',
            responseType: 'blob',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
            params: data
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', data.file);
            document.body.appendChild(link);
            link.click();
          });
    },
}

export default backendApi;
