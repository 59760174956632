import React, {useState, useEffect} from 'react';
import { isMobile } from 'react-device-detect';
import {Button, TextField,
    FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment' // 292.3K (gzipped: 71.6K)
import backendApi from '../../api/BackendApi.js';
import '../../css/Tutors.css';
import '../../css/Tutors-Mobile.css';

const style = {
    box: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    },
    time: {
        width: '15ch',
        variant: "outlined",

    },
    loco: {
        display: 'inline-flex',
    },
    form: {
        width: '15ch',
        variant: 'outlined',
        margin: 'dense',
        m: 2,
    },
    student: {
        width: '35ch',
        variant: 'outlined',
        margin: 'dense',
        m: 2,
        mt: 5,
    }    
};

const CreateProgress = ({user=null}) => {
    const [student, setStudent] = useState(['']);
    const [teks, setTeks] = useState('');
    const [subject, setSubject] = useState('');
    const [assessment, setAssessment] = useState('');
    const [optStudents, setOptStudents] = useState(['']);
    const [day, setDay] = useState(new Date())
    const [lookup, setLookup] = useState([]);
    const optSubjects = [
        {label: 'Math', value: 'Math'}, 
        {label: 'English', value: 'English'}, 
        {label: 'Science', value: 'Science'}
    ];
    const optTeks = [
        {label: '5.3a', value: '5.3a'},
        {label: '5.3b', value: '5.3b'},
        {label: '5.3c', value: '5.3c'}
    ];
    // const optStudents = [
    //     {label: 'Student A', value: 'Student A'},
    //     {label: 'Student B', value: 'Student B'},
    //     {label: 'Student C', value: 'Student C'}
    // ];
    const optAssessments = [
        {label: 'A', value: 'A'},
        {label: 'B', value: 'B'},
        {label: 'C', value: 'C'},
        {label: 'D', value: 'D'},
        {label: 'F', value: 'F'}
    ];
    

    useEffect(() => {        
        const tempStudents = [];
        const tempLookup = [];
        
        const data = {
            tutor: user.id,
        };
        backendApi.get("/student/tutor/", data)
            .then(data => {
                data.map((student) => (
                    tempLookup.push(student.id)
                ));
                data.map((school, idx) => (
                    tempStudents.push({label: school.name, value: idx})
                ));
                setLookup(tempLookup);
                setOptStudents(tempStudents);
            })
            .catch((error) => {
                console.log(error.code)
                if (error.code === 'permission-denied') {
                    // setMsg("Insufficient permission");
                }
            });
    }, [user])

    const handleSubmit = () => {
        const data = {
            tutor: user.id,
            date:  moment(day).format("YYYY-MM-DD"),
            student: lookup[student],
            subject,
            teks,
            assessment,
        };


        backendApi.post("/student_progress/", data)
            .then(result => {
                console.log(result);
            })
            .catch(error => {
                console.log(error.code);
            });
    }

    const handleClear = () => {
    }

    return (
        <div className="body-container">
            <h3> Student Progress </h3>

            <div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Date"
                        value={day}
                        onChange={e => { setDay(e)}}
                        renderInput={params => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </div>

            <div className={isMobile ? "mobile-create-session-div" : "create-session-div"}>
                        
                <div>
                    <FormControl sx={style.student}>
                        <InputLabel id="test-select-label">Student</InputLabel>
                        <Select
                            // sx={{ color: 'black', width: '50ch' }}
                            labelId="test-select-label"
                            id="student"
                            value={student}
                            label="Student"
                            onChange={e=>setStudent(e.target.value)}
                        >
                            {optStudents.map((item, idx) => {
                                return(
                                    <MenuItem key={"optStudents"+idx} value={item.value}>{item.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </div>
                        
                <div>
                    {/* <FormControl sx={style.form}>
                        <InputLabel id="test-select-label">Grade</InputLabel>
                        <Select
                            // sx={{ color: 'black', width: '50ch' }}
                            labelId="test-select-label"
                            id="grade"
                            value={grade}
                            label="Grade"
                            onChange={e=>setGrade(e.target.value)}
                        >
                            {optGrades.map((item, idx) => {
                                return(
                                    <MenuItem key={"optGrades"+idx} value={item.value}>{item.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl> */}

                    <FormControl sx={style.form}>
                        <InputLabel id="test-select-label">Subject</InputLabel>
                        <Select
                            // sx={{ color: 'black', width: '50ch' }}
                            labelId="test-select-label"
                            id="subject"
                            value={subject}
                            label="Subjects"
                            onChange={e=>setSubject(e.target.value)}
                        >
                            {optSubjects.map((item, idx) => {
                                return(
                                    <MenuItem key={"optSubjects"+idx} value={item.value}>{item.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>

                    <FormControl sx={style.form}>
                        <InputLabel id="test-select-label">Teks</InputLabel>
                        <Select
                            // sx={{ color: 'black', width: '50ch' }}
                            labelId="test-select-label"
                            id="teks"
                            value={teks}
                            label="Teks"
                            onChange={e=>setTeks(e.target.value)}
                        >
                            {optTeks.map((item, idx) => {
                                return(
                                    <MenuItem key={"optTeks"+idx} value={item.value}>{item.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>

                    <FormControl sx={style.form}>
                        <InputLabel id="test-select-label">Assessment</InputLabel>
                        <Select
                            // sx={{ color: 'black', width: '50ch' }}
                            labelId="test-select-label"
                            id="assessment"
                            value={assessment}
                            label="Assessment"
                            onChange={e=>setAssessment(e.target.value)}
                        >
                            {optAssessments.map((item, idx) => {
                                return(
                                    <MenuItem key={"optAssessments"+idx} value={item.value}>{item.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </div>
                        
                <Button sx={{ m: 1, width: '20ch' }} variant="outlined" onClick={handleSubmit}>Submit</Button>
                <Button sx={{ m: 1, width: '20ch' }} variant="outlined" onClick={handleClear}>Clear</Button>
                    
            </div>            
        </div>
    )
}

export default CreateProgress;