import React from 'react';
import { Button, Modal, Box} from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment' // 292.3K (gzipped: 71.6K)
import backendApi from '../../api/BackendApi.js';
import '../../css/Tutors.css';

const ConfirmationModal = ({
    open_modal=false,
    handleClose=null,
    user=null,
    month=null,
}) => {
    const desktop = useMediaQuery('(min-width:450px)');

    const style = {
        box: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: desktop ? '70%' : '80%',
            height: desktop ? '30%' : '50%',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        },
    };

    const handleAffirm = (value) => {
        const data = {
            employee: user.id,
            month: moment(month).format("YYYY-MMM"),
        };

        backendApi.post("/confirmation", data)
            .then(result => {
                if (result === 1) {
                    console.log(result);
                } else {
                }
                handleClose();
            })
            .catch(error => {
                console.log(error.code);
            });
    }

    return (
        <Modal
            open={open_modal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style.box}>
                <div>
                    {desktop ?
                    <div>
                        <div> I certify that the time worked as recorded on this form is true </div>
                        <div> and correct to the best of my knowledge.</div>
                    </div>
                    :
                        <div className='confirm-statement'>
                            <div> I certify that the time worked </div>
                            <div> as recorded on this form is </div>
                            <div> true and correct to the best of </div>
                            <div> my knowledge. </div>
                        </div>
                    }

                    <div>
                        <Button 
                            sx={{ m: 1, width: '20ch', }}
                            variant="outlined" 
                            onClick={() => handleAffirm(true)}
                        >
                            Affirm
                        </Button>
                        <Button 
                            sx={{ m: 1, width: '20ch', }}
                            variant="outlined" 
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    </div>
                </div> 
            </Box>
        </Modal>
    );
}

export default ConfirmationModal;