import React, {useState} from 'react';
import {
    Button, Menu, MenuItem, Fade
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useHistory } from "react-router-dom";
import { faBars, faUser, faHome, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import backendApi from '../../api/BackendApi.js';
import './topbar.css';

const Topbar = ({user, signOut, name=''}) => {

    let history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [anchorEl3, setAnchorEl3] = useState(null);
    const [anchorEl4, setAnchorEl4] = useState(null);
    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);
    const open3 = Boolean(anchorEl3);
    const open4 = Boolean(anchorEl4);

    const desktop = useMediaQuery('(min-width:450px)');

    const styles = {
        button: {
            size: 'medium',
            width: '5ch',
            fontSize: '1ch',
        },
    };

    const handleClickView = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClick3 = (event) => {
        setAnchorEl3(event.currentTarget);
    };

    const handleClick4 = (event) => {
        setAnchorEl4(event.currentTarget);
    };

    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const handleClose3 = () => {
        setAnchorEl3(null);
    };

    const handleClose4 = () => {
        setAnchorEl4(null);
    };

    const handleLink = (link) => {
        history.push({
            pathname: link,
          });
        setAnchorEl(null);
        setAnchorEl2(null);
        setAnchorEl3(null);
        setAnchorEl4(null);
    }

    const handleLogout = async () => {
        let param = {email: user.email};
        
        backendApi.update("/user/done/", param)
            .then(result =>{
                signOut();
            })
            .catch(error => {
                console.log(error);
            })
    }

    return (
        <div className='topbar'>
            <div className="topbarWrapper">
                <div className="topLeft">
                    <span className="logo">
                        <div>EMC</div>
                        <div>
                            <Button
                                sx={styles.button}
                                onClick={()=>handleLink('/')}
                            >
                                <FontAwesomeIcon icon={faHome} />
                            </Button>
                        </div>
                    </span>
                </div>
                <div className="topRight">
                    <div className="dropdown">
                        {user !== null ?         
                            <div className="nav-group">
                                {user.role === 'ROLE_ADMIN' ?
                                    <div className="nav-group">

                                        {desktop ?
                                            <Button
                                                id="fade-button"
                                                aria-controls={open ? 'fade-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={open ? 'true' : undefined}
                                                onClick={()=>handleLink('/help')}
                                            >
                                                <FontAwesomeIcon icon={faInfoCircle} />
                                            </Button>
                                            : null
                                        }

                                        <Button
                                            id="fade-button"
                                            aria-controls={open2 ? 'fade-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open2 ? 'true' : undefined}
                                            onClick={handleClickView}
                                        >
                                            <FontAwesomeIcon icon={faBars} />
                                        </Button>
                                        <Menu
                                            id="fade-menu2"
                                            MenuListProps={{
                                                'aria-labelledby': 'fade-button',
                                            }}
                                            anchorEl={anchorEl2}
                                            open={open2}
                                            onClose={handleClose2}
                                            TransitionComponent={Fade}
                                        >
                                            <MenuItem onClick={e=>handleLink("view-employees")}>Employees</MenuItem>
                                            <MenuItem onClick={e=>handleLink("view-schools")}>Schools</MenuItem>
                                            <MenuItem onClick={e=>handleLink("view-timesheet")}>Timesheets</MenuItem>
                                            <MenuItem onClick={e=>handleLink("view-payroll")}>Payroll</MenuItem>
                                            <MenuItem onClick={e=>handleLink("view-invoices")}>Invoices</MenuItem>
                                            <MenuItem onClick={e=>handleLink("view-contracts")}>Contracts</MenuItem>
                                            <MenuItem onClick={e=>handleLink("view-placement")}>Placements</MenuItem>
                                            <MenuItem onClick={e=>handleLink("analytics")}>Analytics</MenuItem>
                                            <MenuItem onClick={e=>handleLink("reset-password")}>Reset Password</MenuItem>
                                        </Menu>
                                    </div>
                                    :
                                    <div className="nav-group">
                                        <Button
                                            id="fade-button3"
                                            aria-controls={open3 ? 'fade-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open3 ? 'true' : undefined}
                                            onClick={handleClick3}
                                        >
                                            <FontAwesomeIcon icon={faBars} />
                                        </Button>
                                        <Menu
                                            id="fade-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'fade-button',
                                            }}
                                            anchorEl={anchorEl3}
                                            open={open3}
                                            onClose={handleClose3}
                                            TransitionComponent={Fade}
                                        >
                                            <MenuItem onClick={e=>handleLink('/')}>Summary</MenuItem>
                                            <MenuItem onClick={e=>handleLink('create-timesheet')}>Timesheet Entry</MenuItem>
                                            <MenuItem onClick={e=>handleLink('view-timesheet')}>View Timesheet</MenuItem>
                                            <MenuItem onClick={e=>handleLink('create-progress')}>Student Progress</MenuItem>
                                        </Menu>
                                    </div>
                                }
                            </div>
                            :
                            null
                        }

                        <div className="nav-group">
                            <Button
                                id="fade-button4"
                                aria-controls={open4 ? 'fade-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open4 ? 'true' : undefined}
                                onClick={handleClick4}
                            >
                                <FontAwesomeIcon icon={faUser} />
                                <div className='space'/>
                                {(user !== null && typeof user.name !== 'undefined' )? user.name.split(' ')[0] : null}
                            </Button>
                            {user !== null ?
                                <Menu
                                    id="fade-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'fade-button',
                                    }}
                                    anchorEl={anchorEl4}
                                    open={open4}
                                    onClose={handleClose4}
                                    TransitionComponent={Fade}
                                >
                                    <MenuItem>{user.email}</MenuItem>
                                    <MenuItem>{user.role === 'ROLE_ADMIN' ?
                                        <div> Position: Admin </div>
                                        : <div> Position: Tutor </div>}</MenuItem>
                                    <MenuItem onClick={handleLogout}>Log Out</MenuItem>
                                </Menu>
                                :
                                <div/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Topbar;
