import React, {useEffect, useState} from 'react';
import {Button,
    Select, MenuItem, TextField, FormControl,
    InputLabel,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import backendApi from '../../api/BackendApi.js';
import Message from '../Message.js';
import ModalPlacement from '../ModalPlacement.js';
import '../../css/Tutors.css';
import '../../css/placement.css';
import { faPlus, faTrash, faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import moment from 'moment' // 292.3K (gzipped: 71.6K)

const Placement = () => {
    const location = useLocation();

    const [optContracts, setOptContracts] = useState([]);
    const [optTutors, setOptTutors] = useState([]);
    const [contract, setContract] = useState('');
    const [assignments, setAssignments] = useState([]);
    const [assignment, setAssignment] = useState(
        {
            id: 0,
            contract: 0,
            tutor: "",
            rate: "",
            subject: "",
            grade: "",
            days: "",
            time: "",
            start: "",
            end: "",
            active: 0,
            name: "",
        });
    const [listSize, setListSize] = useState(0);
    const [contractStartDate, setContractStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [purchaseOrder, setPurchaseOrder] = useState('');
    const [school, setSchool] = useState('');
    const [message, setMessage] = useState("");
    const [open, setOpen] = React.useState(false);
    const [openPlacement, setOpenPlacement] = useState(false);
    const [update, setUpdate] = useState(false);
    const handleClose = () => setOpen(false);

    const desktop = useMediaQuery('(min-width:450px)');

    const style = {
        box: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        },
    
        button: 
            { m: 1, width: '10%' },
    
        select: {
            color: 'black',
            width: '75%',
        }
    };

    function updateSelectList ()
    {        
        let tempTutors = [''];
        backendApi.get("/profile/unassigned/")
            .then(data => {
                data.map(item => (
                    tempTutors.push({label: item.name, value: item.id})
                ));
                setOptTutors(tempTutors);
            })
            .catch((error) => {
                console.log(error.code)
                if (error.code === 'permission-denied') {
                }
            });
    }
    
    useEffect(() => {
        let tempOptions = ['']
        backendApi.get("/contract/all")
            .then(data => {
                data.map((item, idx) => (
                    tempOptions.push({label: item.contract_num, value: item.id, po: item.purchase_order})
                ));
                setOptContracts(tempOptions);
            })
            .catch((error) => {
                console.log(error.code)
                if (error.code === 'permission-denied') {
                }
            });
        
        updateSelectList();
            
        if (location.state) {
            if(Object.keys(location.state).length > 0) {
                setContract(location.state.id);
                setContractStartDate(location.state.start_date);
                setEndDate(location.state.end_date);
                setSchool(location.state.name);
                setPurchaseOrder(location.state.purchase_order);

                let temp = [];
                const param = {contract_id: location.state.id};
                backendApi.get("/placement", param)
                    .then(data => {
                        setListSize(data.length)
                        data.map((item, idx) => (
                            temp.push(item)
                        ));
                        setAssignments(temp);
                    })
                    .catch((error) => {
                        console.log(error.code)
                        if (error.code === 'permission-denied') {
                        }
                    });
            }
        }
    }, [location.state]);

    const handleSelectContract = (value) => {
        setContract(value);
        let obj = optContracts.find(o => o.value === value);
        const data = {number: obj.po};
        backendApi.get("/contract/purchase_order/", data)
            .then(data => {
                setSchool(data.contract.school);
                setContractStartDate(data.contract.start_date);
                setEndDate(data.contract.end_date);
                setPurchaseOrder(data.contract.purchase_order === null ? '' : 
                    data.contract.purchase_order);
                setListSize(data.tutors.length);
                let temp = [];
                data.tutors.map(item => (
                    temp.push(item)
                ));
                setAssignments(temp);
            })
            .catch((error) => {
                console.log(error.code)
                if (error.code === 'permission-denied') {
                }
            });
    }

    const handleSubmit = () => {
        setOpenPlacement(false);
        setUpdate(false);
        if((assignment.tutor === '') || (assignment.tutor === null)) {
            setMessage("Select tutor");
            setOpen(true);
        } else if((contract === '') || (contract === null)) {
            setMessage("Select contract");
            setOpen(true);
        } else {
            let clone = {...assignment};
            clone.start = moment(assignment.start).format("YYYY-MM-DD");
            clone.rate = parseFloat(assignment.rate);
            clone.active = true;
            clone.contract = contract;
            delete clone.name;

            backendApi.post("/placement", clone)
                .then(result => {
                    
                    if (result === true) {
                        let clone = {...assignment};
                        clone.tutor = '';
                        setAssignment(clone);
    
                        const param = {contract_id: contract};
                        backendApi.get("/placement", param)
                            .then(data => {
                                setListSize(data.length);
                                let temp = [];
                                data.map(item  => (
                                    temp.push(item)
                                ));
                                setAssignments(temp);
                            })
                            .catch((error) => {
                                console.log(error.code)
                                if (error.code === 'permission-denied') {
                                }
                            });
                    } else {
                        setMessage('Data not saved.');
                        setOpen(true);
                    }
                })
                .catch(error => {
                    console.log(error.code);
                });
        }
    }

    const handleSelectTutor= (value) => {
        let result = optTutors.filter(obj => {
            return obj.value === value
        })
        let clone = {...assignment};
        clone.tutor = value;
        clone.name = result[0].label;
        setAssignment(clone);
    }

    const deleteTutor = (value) => {
        const data = {
            id: value.id,
        };

        backendApi.delete("/placement", data)
            .then(result => {
                if (result === true) {
                    setMessage('Data deleted.')
                    setOpen(true);
    
                    let temp = [];
                    const param = {contract_id: contract};
                    backendApi.get("/placement", param)
                        .then(data => {
                            data.map(item => (
                                temp.push(item)
                            ));
                            setListSize(data.length);
                            setAssignments(temp);
                        })
                        .catch((error) => {
                            console.log(error.code)
                            if (error.code === 'permission-denied') {
                            }
                        });
                } else {
                    setMessage('Data not deleted.');
                    setOpen(true);
                }
            })
            .catch((error) => {
                console.log(error.code)
                if (error.code === 'permission-denied') {
                }
            });
    }

    const handleModify = () => {
        setOpenPlacement(false);
        setUpdate(false);
        if((assignment.tutor === '') || (assignment.tutor === null)) {
            setMessage("Select tutor");
            setOpen(true);
        } else if((contract === '') || (contract === null)) {
            setMessage("Select contract");
            setOpen(true);
        } else {
            let clone = {...assignment};
            clone.start = moment(assignment.start).format("YYYY-MM-DD");
            clone.rate =  parseFloat(assignment.rate)
            clone.active = true;
            clone.contract = contract;
            delete clone.name;

            backendApi.update("/placement", clone)
                .then(result => {
                    
                    if (result === true) {
                        let clone = {...assignment};
                        clone.tutor = '';
                        setAssignment(clone);
    
                        const param = {contract_id: contract};
                        backendApi.get("/placement", param)
                            .then(data => {
                                setListSize(data.length);
                                let temp = [];
                                data.map(item  => (
                                    temp.push(item)
                                ));
                                setAssignments(temp);
                            })
                            .catch((error) => {
                                console.log(error.code)
                                if (error.code === 'permission-denied') {
                                }
                            });
                    } else {
                        setMessage('Data not saved.');
                        setOpen(true);
                    }
                })
                .catch(error => {
                    console.log(error.code);
                });
        }
    }

    const handleSelectAssignment = (param) => {
        let clone = {...param};
        clone.start = moment(param.start);
        setAssignment(clone);
        setOpenPlacement(true)
        setUpdate(true);
    }
    
    const handlePlacementCancel = () => {
        let temp = {
            active: 0,
            contract: 0,
            days: "",
            end: "",
            grade: "",
            id: 0,
            name: "",
            rate: "",
            start: new Date(),
            subject: "",
            time: "",
            tutor: ''
        }
        setAssignment(temp);
        setUpdate(false);
        setOpenPlacement(false);
    }

    return (
        <div className="body-container">

            <Message
                message={message}
                open={open}
                btn1={'OK'}
                handleClose={handleClose}
                handleBtn1={handleClose}
            />

            <ModalPlacement
                open={openPlacement}
                update={update}
                handleOk={handleSubmit}
                handleModify={handleModify}
                handleCancel={handlePlacementCancel}
                assignment={assignment}
                setAssignment={setAssignment}
            />

            <h4> Tutor Placement </h4>
            <div className='placement-whole'>
                <div className="placement-left-side">

                    <FormControl
                        variant="filled"
                        // margin={"dense"}
                        sx={{ m: 1, width: '70%' }}
                    >
                        <InputLabel id="test-select-label">Contract</InputLabel>
                        <Select
                            labelId="test-select-label"
                            id="contract"
                            value={contract}
                            name="contract"
                            label="Contract"
                            onChange={e=>handleSelectContract(e.target.value)}
                        >
                            {optContracts.map((item, idx) => {
                                return(
                                    <MenuItem key={"optContracts"+idx} value={item.value}>{item.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>

                    <TextField
                        sx={{ m: 1, width: '70%' }}
                        // required
                        variant="filled"
                        id="school"
                        label="School"
                        value={school || ''}
                    />

                    <TextField
                        sx={{ m: 1, width: '70%' }}
                        // required
                        variant="filled"
                        id="contractStartDate"
                        label="Start Date"
                        value={contractStartDate || ''}
                    />

                    <TextField
                        sx={{ m: 1, width: '70%' }}
                        // required
                        variant="filled"
                        id="endDate"
                        label="End Date"
                        value={endDate || ''}
                    />

                    <TextField
                        sx={{ m: 1, width: '70%' }}
                        // required
                        variant="filled"
                        id="purchaseOrder"
                        label="Purchase Order"
                        value={purchaseOrder || ''}
                    />
                </div>


                <div className='placement-right-side'>
                    {desktop ? 
                    <div className='placement-select-tutor-div'>
                        <div className='add-item-box'>
                            <Select
                                sx={style.select}
                                disableUnderline
                                labelId="test-select-label"
                                id="tutor"
                                name="tutor"
                                value={assignment.tutor}
                                label="Tutor"
                                variant="standard"
                                underline="none"
                                onChange={e=>handleSelectTutor(e.target.value)}
                            >
                                {optTutors.map((item, idx) => {
                                    return(
                                        <MenuItem key={"ts_tutors_"+idx} value={item.value}>{item.label}</MenuItem>
                                    )
                                })}
                            </Select>

                            <Button 
                                sx={style.button} 
                                variant="outlined"
                                // onClick={handleSubmit}
                                onClick={()=>setOpenPlacement(true)}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                        </div>
                    </div> : null}

                    <div className='number-tutors'> # of Tutors on site = {listSize}</div>

                    <div className='tutor-list-header-container'>
                        <div className='tutor-list-header'>
                            <div className='placement-list-name'>Name</div>
                            <div className='placement-list-rate'>Rate</div>
                            <div className='placement-list-start'>Start</div>
                            <div className='placement-list-subject'>Subject</div>
                        </div>
                    </div>

                    <div className='item-list'>
                        {assignments.map((item, index) => (
                            <div className='item-container' key={"tutor_list"+index}>
                                <div className='item-info'>
                                    <div className='placement-list-name'>{item.name}</div>
                                    <div className='placement-list-rate'>${item.rate}</div>
                                    <div className='placement-list-start'>{item.start}</div>
                                    {desktop ? 
                                        <div className='placement-list-subject'>{item.subject}</div> : null
                                    }
                                </div>
                                {desktop ? 
                                    <div>
                                        <Button 
                                            sx={style.button} 
                                            onClick={() => handleSelectAssignment(item)} 
                                        >
                                            <FontAwesomeIcon icon={faEdit} />
                                        </Button>
                                    </div>
                                : 
                                    null
                                }
                                {desktop ? 
                                    <div>
                                        <Button 
                                            sx={style.button} 
                                            onClick={() => deleteTutor(item)} 
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </Button>
                                    </div>
                                : 
                                    null
                                }
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Placement;