import React from 'react';
import { Button } from '@mui/material';
import { useHistory } from "react-router-dom";
import '../css/Tutors.css';

const styles = {
    btn: {
        m: 1,
        width: '20ch',
        height:'8ch'
    },
  };

const AdminHome = () => {
    let history = useHistory();

    const handleLink = (link) => {
        history.push({
            pathname: link,
          });
    }

    return (
        <div className="body-container">
            <h3> EMC Admin Page </h3>
            <div> Welcome to the EMC Tutors web portal. </div>
            <div> Use this portal to manage tutors, schools, and invoices. </div>
            <div>
                Data can be viewed online and/or downloaded into a pdf or excel spreadsheet.
            </div>
            <div className='button-nav-group'>
                <div>
                    <Button sx={styles.btn} variant="outlined" onClick={e=>handleLink("view-employees")}>Employees</Button>
                    <Button sx={styles.btn} variant="outlined" onClick={e=>handleLink("view-schools")}>Schools</Button>
                    <Button sx={styles.btn} variant="outlined" onClick={e=>handleLink("view-timesheet")}>Timesheets</Button>
                    <Button sx={styles.btn} variant="outlined" onClick={e=>handleLink("view-payroll")}>Payroll</Button>
                </div>
                <div>
                    <Button sx={styles.btn} variant="outlined" onClick={e=>handleLink("view-contracts")}>Contracts</Button>
                    <Button sx={styles.btn} variant="outlined" onClick={e=>handleLink("view-placement")}>Placements</Button>
                    <Button sx={styles.btn} variant="outlined" onClick={e=>handleLink("view-invoices")}>Invoices</Button>
                    <Button sx={styles.btn} variant="outlined" onClick={e=>handleLink("analytics")}>Analytics</Button>
                    <Button sx={styles.btn} variant="outlined" onClick={e=>handleLink("reset-password")}>Reset Password</Button>
                </div>
            </div>
        </div>
    );
}

export default AdminHome;