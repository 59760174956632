import React, {useState, useEffect} from 'react';
import { BarChart, Bar,  XAxis, YAxis,
    CartesianGrid, Tooltip, Legend,
    ResponsiveContainer
} from 'recharts';
import { Paper } from "@mui/material";
import backendApi from '../../api/BackendApi.js';
import moment from 'moment' // 292.3K (gzipped: 71.6K)
import '../../css/Tutors.css';

const Analytics = () => {
    const [analytics, setAnalytics] = useState([]);
    const [schools, setSchools] = useState([]);

    
    useEffect(() => {
        let data = {
            year: moment().format("YYYY"),
        };
        backendApi.get('/timesheet/analytics_hours', data)
            .then(data => {
                setSchools(data.schools)
                setAnalytics(data.hours);
            })
            .catch((error) => {
                console.log(error.code)
                if (error.code === 'permission-denied') {
                    // setMsg("Insufficient permission");
                }
            });
    }, [])

    const styles = {
        page: {
            margin: '2ch auto',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            justifyContent: "center",
            alignItems: "center",
            direction: "column"
        }
    };

    const colors = [ "#8884d8", "#f8e952", "#30ca9d", "#7C9FF7", "#d3bec2", 
        "#ff27c7", "#fa8072", "#e6a91d", "#0303fc", "#c1f540" ];

    return (
        <div className="body-container">
            <div className='analytics-charts'>
                <Paper sx={styles.page}>
                    <div> Hours Billed </div>
                    <ResponsiveContainer className="container" height='100%' width='100%'>
                        <BarChart
                            width={500}
                            height={300}
                            data={analytics}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                            label={"Title"}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            {schools.map((value, idx) => (
                                <Bar key={"bar"+idx} dataKey={value} fill={colors[idx]} />
                            ))}
                        </BarChart>
                    </ResponsiveContainer>
                </Paper>
            </div>
        </div>
    );
}

export default Analytics;