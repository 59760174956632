import React, {useState, useEffect} from 'react';
// import PropTypes from 'prop-types';
import { 
    MenuItem, InputLabel, FormControl, Select,
    Button,
} from "@mui/material";
// import generatePDF from '../GeneratePdf.js';
import Message from '../Message.js';
import backendApi from '../../api/BackendApi.js';
import '../../css/Tutors.css';

const ResetPassword = ({user=null}) => {
    const [tutorId, setTutorId] = useState('');
    const [tutorName, setTutorName] = useState('');
    const [optTutors, setOptTutors] = useState(['']);
    const [msg, setMsg] = useState('');
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    
    useEffect(() => {
        const tempTutors = [''];
        backendApi.get("/profile/all/")
            .then(data => {
                data.map((item, idx) => (
                    tempTutors.push({label: item.name, value: item.user_id})
                ));
                setOptTutors(tempTutors);
            })
            .catch((error) => {
                console.log(error.code)
                if (error.code === 'permission-denied') {
                }
            });
    }, [])

    const handleSelectTutor = (value) => {
        const results = optTutors.filter(entry => { return entry.value === value; });
        setTutorId(value);
        setTutorName(results[0].label);
    }

    const handleOpenModal = () => {
        setMsg('Reset password for ' + tutorName)
        setOpen(true);
    }

    const handleOk = () => {
        setOpen(false);
        const data = {
            tutor: tutorId
        }
        backendApi.update("/user/resetw/", data)
            .then(result => {
                console.log(result);
            })
            .catch(error => {
                console.log(error.code);
            });
    }

    const handleCancel = () => {
        setOpen(false);
    }

    return (
        <div className="body-container">
            <h3> Reset Password </h3>

            <Message
                message={msg}
                open={open}
                btn1={'OK'}
                btn2={'Cancel'}
                handleClose={handleClose}
                handleBtn1={handleOk}
                handleBtn2={handleCancel}
            />

            {user.role === 'ROLE_ADMIN' ?
                <div className="filter-attendance">
                    <div>
                        <FormControl
                            variant="outlined"
                            margin={"dense"}
                            style={{ marginBottom: 32 }}
                        >
                            <InputLabel id="test-select-label">Tutor</InputLabel>
                            <Select
                                sx={{ color: 'black', width: '50ch' }}
                                labelId="test-select-label"
                                id="tutor"
                                value={tutorId}
                                label="tutor"
                                onChange={e=>handleSelectTutor(e.target.value)}
                            >
                                {optTutors.map((item, idx) => {
                                    return(
                                        <MenuItem key={"ts_tutors_"+idx} value={item.value}>{item.label}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>

                    </div>

                    <Button
                        sx={{ m: 1, width: '40ch' }}
                        variant="outlined"
                        onClick={handleOpenModal}
                    >
                        Reset Password
                    </Button>
                </div>
                :null
            }
        </div>
    );
}

// ViewTimeSheets.propTypes = {
//     name: PropTypes.string
//   };

export default ResetPassword;