import React, {useState, useEffect} from 'react';
import { Router, Switch, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import LogIn from './components/LogIn.js';
import CreateTimeSheet from './components/create/CreateTimeSheet.js';
import CreatePlacement from './components/create/CreatePlacement.js';
import CreatePayment from './components/create/CreatePayment.js'
import CreateProgress from './components/create/CreateProgress.js';
import CreateInvoice from './components/create/CreateInvoice';
import ViewTimeSheets from './components/view/ViewTimeSheets.js';
import ViewTutors from './components/view/ViewTutors.js';
import ViewSchools from './components/view/ViewSchools.js';
import ViewContracts from './components/view/ViewContracts.js';
import ViewEmployee from './components/view/ViewEmployee.js';
import Topbar from './components/topbar/Topbar.jsx';
import history from './history';
import ResetPassword from './components/create/ResetPassword.js';
import Summary from './components/view/ViewSummary.js';
import Analytics from './components/view/ViewAnalytics.js';
import AdminHome from './components/AdminHome.js';
import Help from './components/Help.js';
import "./css/App.css";

const theme = createTheme({
    typography: {
      fontFamily: [
        'Nunito',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif'
      ].join(','),
     fontSize: 12,
    }
  });

function App() {
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (sessionStorage.getItem('user') !== null) {
            setUser(JSON.parse(sessionStorage.getItem('user')));
        }
    }, [])

    const verifyLogin = (result) => {
        sessionStorage.setItem('user', JSON.stringify(result));
        setUser(result);
        history.push("/");
    }

    const signOut = async () => {
        setUser(null);
        sessionStorage.removeItem('user');
        localStorage.removeItem('timeIn');
        localStorage.removeItem('timeOut');
        localStorage.removeItem('hoursWorked');
        localStorage.removeItem('break');
    }

    return (
        <ThemeProvider theme={theme}>
        <div className="app-container">
            <Router history={history}>
                {!user ?
                    <div className="page-container">
                        <LogIn verifyLogin={verifyLogin} />
                    </div>
                    :
                    <div className="page-container">
                        <Topbar 
                            user={user}
                            signOut={signOut}
                        />

                        <Switch>
                            <Route path="/create-timesheet"> <CreateTimeSheet user={user}/> </Route>
                            <Route path="/view-payroll"> <CreatePayment user={user}/> </Route>
                            <Route path="/reset-password"> <ResetPassword user={user}/> </Route>
                            <Route path="/create-progress"> <CreateProgress user={user} /> </Route>                           
                            <Route path="/view-employees"> <ViewTutors /> </Route>
                            <Route path="/view-schools"> <ViewSchools /> </Route>
                            <Route path="/view-timesheet"> <ViewTimeSheets user={user}/> </Route>
                            <Route path="/view-contracts"> <ViewContracts /> </Route>
                            <Route path="/view-placement"> <CreatePlacement /> </Route>
                            <Route path="/analytics"> <Analytics /> </Route>
                            <Route path="/employee-summary"> <ViewEmployee /> </Route>
                            <Route path="/view-invoices"> <CreateInvoice user={user} /> </Route>
                            <Route path="/help"> <Help /> </Route>
                            {user.role === 'ROLE_ADMIN' ?
                                <Route path="/"> <AdminHome /> </Route> :
                                <Route path="/"> <Summary user={user}/> </Route>
                            }
                        </Switch>
                    </div>
                }
            </Router>
        </div>
      </ThemeProvider>
    );
}

export default App;
